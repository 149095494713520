import React from 'react';
import './RunningPageLine.css';
import { Link } from 'react-router-dom';
import arrowRight from './img/arrowRight.svg';

export default function RunningPageLine(props) {
  return (
    <Link to={props.action}>
      <div className={props.margin ? 'runningPageLine margin' : 'runningPageLine'}>
        <div className={props.margin ? 'runnerHolder right' : 'runnerHolder left'}>
          <div className={props.margin ? 'basic l1624' : 'basic l2099'}>
            {props.imgs.map((item) => (
              <div className="icon" key={item}>
                <h1>{props.text}</h1>
                <img alt="" className="mainImg" src={item} />
                <img alt="" className="arrowRight" src={arrowRight} />
              </div>
            )) }
            {props.imgs.map((item) => (
              <div className="icon" key={item}>
                <h1>{props.text}</h1>
                <img alt="" className="mainImg" src={item} />
                <img alt="" className="arrowRight" src={arrowRight} />
              </div>
            )) }
            {props.imgs.map((item) => (
              <div className="icon" key={item}>
                <h1>{props.text}</h1>
                <img alt="" className="mainImg" src={item} />
                <img alt="" className="arrowRight" src={arrowRight} />
              </div>
            )) }
          </div>
          <div className={props.margin ? 'shadow l1624' : 'shadow l2099'}>
            {props.imgs.map((item) => (
              <div className="icon" key={item}>
                <h1>{props.text}</h1>
                <img alt="" className="mainImg" src={item} />
                <img alt="" className="arrowRight" src={arrowRight} />
              </div>
            )) }
            {props.imgs.map((item) => (
              <div className="icon" key={item}>
                <h1>{props.text}</h1>
                <img alt="" className="mainImg" src={item} />
                <img alt="" className="arrowRight" src={arrowRight} />
              </div>
            )) }
            {props.imgs.map((item) => (
              <div className="icon" key={item}>
                <h1>{props.text}</h1>
                <img alt="" className="mainImg" src={item} />
                <img alt="" className="arrowRight" src={arrowRight} />
              </div>
            )) }
          </div>

        </div>
      </div>
    </Link>

  );
}
