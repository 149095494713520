import React from 'react';
import './FilledFromLeft.css';

export default function FilledFromLeft(props) {
  return (
    <button type="button" data-strength-text="15" data-strength="35" className="filledFromLeft magnetic">
      <span>{props.name}</span>
      <div className="buttonBackLong" />

    </button>
  );
}
