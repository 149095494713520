import React from 'react';
import { Link } from 'react-router-dom';
import ClientCard from '../../atoms/ClientCard/ClientCard';
import './Clients.css';
import ReviewsSlider from '../../atoms/ReviewsSlider/ReviewsSlider';
import FilledFromLeft from '../../../../shared_components/FilledFromLeft/FilledFromLeft';
import { clients } from './clients';

export default function Clients() {
  return (
    <div className="clients">
      <div className="background">
        <div id="blackSticky" className="black">
          <div data-scroll-speed="1" data-scroll-offset="0%, 43%" data-scroll="" data-scroll-target="#blackSticky" data-scroll-sticky="" className="content">
            <h2>Клиенты</h2>
            <p>
              Среди наших клиентов есть разные компании:
              <span> </span>
              <br className="pc-br" />
              от стартапов
              <span> </span>
              <br className="br-900" />
              до лидеров рынка.
              Их объединяет желание найти
              <span> </span>
              <br className="br-1024" />
              оптимальные решения для своего бизнеса,
              <span> </span>
              <br className="br-900" />
              в чём мы с удовольствием помогаем.
            </p>
            <Link to="/about">
              <FilledFromLeft name="Подробнее о нас" />
            </Link>
          </div>
        </div>
        <div className="grey">
          <div data-scroll-offset="20%" data-scroll="" data-scroll-class="fadeUp-active" className="content toFadeUp">
            <div className="row cardsHolder">
              <div className="col">
                {clients.map((item, id) => {
                  if ((id + 1) % 2 === 1) {
                    return (
                      <ClientCard
                        img={item.img}
                        description={item.description}
                        title={item.title}
                        key={item.title}
                      />
                    );
                  }
                  return ('');
                })}
              </div>
              <div className="col">
                {clients.map((item, id) => {
                  if ((id + 1) % 2 === 0) {
                    return (
                      <ClientCard
                        img={item.img}
                        description={item.description}
                        title={item.title}
                        key={item.title}
                      />
                    );
                  }
                  return ('');
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReviewsSlider />
    </div>
  );
}
