import React, { useEffect } from 'react';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ProjectBanner from './atoms/ProjectBanner/ProjectBanner';
import ProjectAbout from './atoms/ProjectAbout/ProjectAbout';
import MainScreens from './atoms/MainScreens/MainScreens';
import Footer from '../../shared_components/Footer/Footer';
import LetsWorkTogether from '../../shared_components/LetsWorkTogether/LetsWorkTogether';
import NextProject from './atoms/NextProject/NextProject';
import Header from '../../shared_components/Header/Header';
import './ProjectPage.css';

// 1600px
import bannerImgX1600 from './img/gremm/1600/projectBannerImg.jpg';
import projectImgX1600 from './img/gremm/1600/projectPage.jpg';
import screenImg1X1600 from './img/gremm/1600/screenImg1.jpg';
import screenImg2X1600 from './img/gremm/1600/screenImg2.jpg';
import screenImg3X1600 from './img/gremm/1600/screenImg3.jpg';
import screenImg4X1600 from './img/gremm/1600/screenImg4.jpg';
import screenRestaurant1X1600 from './img/gremm/1600/screenRestaurant1.jpg';
import screenRestaurant2X1600 from './img/gremm/1600/screenRestaurant2.jpg';
import screenAccount1X1600 from './img/gremm/1600/screenAccount1.jpg';
import screenAccount2X1600 from './img/gremm/1600/screenAccount2.jpg';
import screenAccount3X1600 from './img/gremm/1600/screenAccount3.jpg';
import screenAccount4X1600 from './img/gremm/1600/screenAccount4.jpg';
import screenLoyalty1X1600 from './img/gremm/1600/screenLoyalty1.jpg';
import screenOnboarding1X1600 from './img/gremm/1600/screenOnboarding1.jpg';
import screenOnboarding2X1600 from './img/gremm/1600/screenOnboarding2.jpg';
import screenOnboarding3X1600 from './img/gremm/1600/screenOnboarding3.jpg';
import screenApp1X1600 from './img/gremm/1600/screenApp1.jpg';

// 1000px
import bannerImgX1000 from './img/gremm/1000/projectBannerImg.jpg';
import projectImgX1000 from './img/gremm/1000/projectPage.jpg';
import screenImg1X1000 from './img/gremm/1000/screenImg1.jpg';
import screenImg2X1000 from './img/gremm/1000/screenImg2.jpg';
import screenImg3X1000 from './img/gremm/1000/screenImg3.jpg';
import screenImg4X1000 from './img/gremm/1000/screenImg4.jpg';
import screenRestaurant1X1000 from './img/gremm/1000/screenRestaurant1.jpg';
import screenRestaurant2X1000 from './img/gremm/1000/screenRestaurant2.jpg';
import screenAccount1X1000 from './img/gremm/1000/screenAccount1.jpg';
import screenAccount2X1000 from './img/gremm/1000/screenAccount2.jpg';
import screenAccount3X1000 from './img/gremm/1000/screenAccount3.jpg';
import screenAccount4X1000 from './img/gremm/1000/screenAccount4.jpg';
import screenLoyalty1X1000 from './img/gremm/1000/screenLoyalty1.jpg';
import screenOnboarding1X1000 from './img/gremm/1000/screenOnboarding1.jpg';
import screenOnboarding2X1000 from './img/gremm/1000/screenOnboarding2.jpg';
import screenOnboarding3X1000 from './img/gremm/1000/screenOnboarding3.jpg';
import screenApp1X1000 from './img/gremm/1000/screenApp1.jpg';

// 640px
import bannerImgX640 from './img/gremm/640/projectBannerImg.jpg';
import projectImgX640 from './img/gremm/640/projectPage.jpg';
import screenImg1X640 from './img/gremm/640/screenImg1.jpg';
import screenImg2X640 from './img/gremm/640/screenImg2.jpg';
import screenImg3X640 from './img/gremm/640/screenImg3.jpg';
import screenImg4X640 from './img/gremm/640/screenImg4.jpg';
import screenRestaurant1X640 from './img/gremm/640/screenRestaurant1.jpg';
import screenRestaurant2X640 from './img/gremm/640/screenRestaurant2.jpg';
import screenAccount1X640 from './img/gremm/640/screenAccount1.jpg';
import screenAccount2X640 from './img/gremm/640/screenAccount2.jpg';
import screenAccount3X640 from './img/gremm/640/screenAccount3.jpg';
import screenAccount4X640 from './img/gremm/640/screenAccount4.jpg';
import screenLoyalty1X640 from './img/gremm/640/screenLoyalty1.jpg';
import screenOnboarding1X640 from './img/gremm/640/screenOnboarding1.jpg';
import screenOnboarding2X640 from './img/gremm/640/screenOnboarding2.jpg';
import screenOnboarding3X640 from './img/gremm/640/screenOnboarding3.jpg';
import screenApp1X640 from './img/gremm/640/screenApp1.jpg';

// 320px
import bannerImgX320 from './img/gremm/320/projectBannerImg.jpg';
import projectImgX320 from './img/gremm/320/projectPage.jpg';
import screenImg1X320 from './img/gremm/320/screenImg1.jpg';
import screenImg2X320 from './img/gremm/320/screenImg2.jpg';
import screenImg3X320 from './img/gremm/320/screenImg3.jpg';
import screenImg4X320 from './img/gremm/320/screenImg4.jpg';
import screenRestaurant1X320 from './img/gremm/320/screenRestaurant1.jpg';
import screenRestaurant2X320 from './img/gremm/320/screenRestaurant2.jpg';
import screenAccount1X320 from './img/gremm/320/screenAccount1.jpg';
import screenAccount2X320 from './img/gremm/320/screenAccount2.jpg';
import screenAccount3X320 from './img/gremm/320/screenAccount3.jpg';
import screenAccount4X320 from './img/gremm/320/screenAccount4.jpg';
import screenLoyalty1X320 from './img/gremm/320/screenLoyalty1.jpg';
import screenOnboarding1X320 from './img/gremm/320/screenOnboarding1.jpg';
import screenOnboarding2X320 from './img/gremm/320/screenOnboarding2.jpg';
import screenOnboarding3X320 from './img/gremm/320/screenOnboarding3.jpg';
import screenApp1X320 from './img/gremm/320/screenApp1.jpg';

import bannerImgVitaX1600 from './img/vitakit/1600/projectBannerImg.jpg';
import bannerImgVitaX1000 from './img/vitakit/1000/projectBannerImg.jpg';
import bannerImgVitaX640 from './img/vitakit/640/projectBannerImg.jpg';
import bannerImgVitaX320 from './img/vitakit/320/projectBannerImg.jpg';

let bannerImg;
let projectImg;
let screenImg1;
let screenImg2;
let screenImg3;
let screenImg4;
let screenRestaurant1;
let screenRestaurant2;
let screenAccount1;
let screenAccount2;
let screenAccount3;
let screenAccount4;
let screenLoyalty1;
let screenOnboarding1;
let screenOnboarding2;
let screenOnboarding3;
let screenApp1;
let bannerImgVita;

function updateImgs() {
  if (window.innerWidth >= 1200) {
    bannerImg = bannerImgX1600;
    projectImg = projectImgX1600;
    screenImg1 = screenImg1X1600;
    screenImg2 = screenImg2X1600;
    screenImg3 = screenImg3X1600;
    screenImg4 = screenImg4X1600;
    screenRestaurant1 = screenRestaurant1X1600;
    screenRestaurant2 = screenRestaurant2X1600;
    screenAccount1 = screenAccount1X1600;
    screenAccount2 = screenAccount2X1600;
    screenAccount3 = screenAccount3X1600;
    screenAccount4 = screenAccount4X1600;
    screenLoyalty1 = screenLoyalty1X1600;
    screenOnboarding1 = screenOnboarding1X1600;
    screenOnboarding2 = screenOnboarding2X1600;
    screenOnboarding3 = screenOnboarding3X1600;
    screenApp1 = screenApp1X1600;
    bannerImgVita = bannerImgVitaX1600;
  } else if (window.innerWidth < 1200 && window.innerWidth >= 900) {
    bannerImg = bannerImgX1000;
    projectImg = projectImgX1000;
    screenImg1 = screenImg1X1000;
    screenImg2 = screenImg2X1000;
    screenImg3 = screenImg3X1000;
    screenImg4 = screenImg4X1000;
    screenRestaurant1 = screenRestaurant1X1000;
    screenRestaurant2 = screenRestaurant2X1000;
    screenAccount1 = screenAccount1X1000;
    screenAccount2 = screenAccount2X1000;
    screenAccount3 = screenAccount3X1000;
    screenAccount4 = screenAccount4X1000;
    screenLoyalty1 = screenLoyalty1X1000;
    screenOnboarding1 = screenOnboarding1X1000;
    screenOnboarding2 = screenOnboarding2X1000;
    screenOnboarding3 = screenOnboarding3X1000;
    screenApp1 = screenApp1X1000;
    bannerImgVita = bannerImgVitaX1000;
  } else if (window.innerWidth < 900 && window.innerWidth >= 600) {
    bannerImg = bannerImgX640;
    projectImg = projectImgX640;
    screenImg1 = screenImg1X640;
    screenImg2 = screenImg2X640;
    screenImg3 = screenImg3X640;
    screenImg4 = screenImg4X640;
    screenRestaurant1 = screenRestaurant1X640;
    screenRestaurant2 = screenRestaurant2X640;
    screenAccount1 = screenAccount1X640;
    screenAccount2 = screenAccount2X640;
    screenAccount3 = screenAccount3X640;
    screenAccount4 = screenAccount4X640;
    screenLoyalty1 = screenLoyalty1X640;
    screenOnboarding1 = screenOnboarding1X640;
    screenOnboarding2 = screenOnboarding2X640;
    screenOnboarding3 = screenOnboarding3X640;
    screenApp1 = screenApp1X640;
    bannerImgVita = bannerImgVitaX640;
  } else if (window.innerWidth < 600) {
    bannerImg = bannerImgX320;
    projectImg = projectImgX320;
    screenImg1 = screenImg1X320;
    screenImg2 = screenImg2X320;
    screenImg3 = screenImg3X320;
    screenImg4 = screenImg4X320;
    screenRestaurant1 = screenRestaurant1X320;
    screenRestaurant2 = screenRestaurant2X320;
    screenAccount1 = screenAccount1X320;
    screenAccount2 = screenAccount2X320;
    screenAccount3 = screenAccount3X320;
    screenAccount4 = screenAccount4X320;
    screenLoyalty1 = screenLoyalty1X320;
    screenOnboarding1 = screenOnboarding1X320;
    screenOnboarding2 = screenOnboarding2X320;
    screenOnboarding3 = screenOnboarding3X320;
    screenApp1 = screenApp1X320;
    bannerImgVita = bannerImgVitaX320;
  }
}
updateImgs();

const initPageData = {
  banner: {
    name: 'Грэмм Групп ',
    services: 'Дизайн приложений iOS и Android, разработка',
    image: bannerImg,
    buttonText: 'Смотреть приложение',
    buttonLinkIOS: '#',
    buttonLinkAndroid: '#',
  },
  about: {
    text: 'Центральный рынок на Янгеля — один из проектов Грэмм Групп. На рынке есть банный комплекс, корнеры с едой и рестораны. Мы сделали приложение для посетителей рынка, в котором они смогут оплачивать парковку, заказывать услуги и еду в ресторанах, бронировать посещения термального комплекса и оплачивать проходы.',
    tasks: [
      'Анализ рынка',
      'Проектирование структуры',
      'Дизайн приложения iOS и Android',
      'Разработка приложения iOS и Android',
    ],
    year: '2023, лето',
  },
  firstImage: projectImg,
  mainScreens: {
    isScreens: true,
    label: 'Основные экраны',
    text: 'Основной функционал приложения — услуги термального комплекса. Пользователь может записываться на услуги, покупать разовые проходы, оплачивать абонемент и просматривать запланированные сеансы парений в расписании',
    images: [{
      img: screenImg1,
      type: 'largeImg',
    }, {
      img: screenImg2,
      type: 'mediumImg',
    }, {
      img: screenImg3,
      type: 'mediumImg',
    }, {
      img: screenImg4,
      type: 'largeImg',
    }],
  },
  restaurants: {
    isScreens: true,
    label: 'Рестораны',
    text: 'В приложении можно заказать еду из ресторана внутри комплекса и доставку на дом. Все заказы, сделанные внутри комплекса, можно оплатить в конце посещения. Пользователь может оплатить онлайн весь счёт, либо его часть, а остаток на кассе',
    images: [{
      img: screenRestaurant1,
      type: 'largeImg',
    }, {
      img: screenRestaurant2,
      type: 'longLowImgH',
    }],
  },
  account: {
    isScreens: false,
    title: 'Профиль',
    blocks: [{
      label: 'Описание',
      p: 'В профиле расположены данные пользователя, карта лояльности, оплата счёта, бронирование проходов и оплата парковки. Также есть возможность управления адресами и способами оплаты.',
      type: 'textBlock',
    }, {
      img: screenAccount1,
      type: 'mediumImg',
    }, {
      img: screenAccount2,
      type: 'largeImg',
    }, {
      img: screenAccount3,
      type: 'longLowImg',
    }, {
      img: screenAccount4,
      type: 'mediumImg',
    }, {
      label: 'Описание',
      p: 'С поддержкой можно созвониться по телефону или написать сотруднику в чате. В чате можно приложить изображения к сообщению.',
      type: 'textBlockRight',
    }],
  },
  loyalty: {
    isScreens: true,
    label: 'Карта лояльности',
    text: 'Карта лояльности расположена в профиле. Существует несколько уровней карты лояльности: уровень карты лояльности зависит от количества посещений и суммы оплат. Баллы с карты лояльности можно потратить в магазине комплекса, сделав заказ для себя или для друга',
    images: [{
      img: screenLoyalty1,
      type: 'largeImg',
    }],
  },
  onboarding: {
    isScreens: false,
    title: 'Главная и онбординг',
    blocks: [{
      label: 'Описание',
      p: 'На главной расположены блоки с основными действиями: оплата счёта, покупка проходов, обратная связь и авторизация за бонусы. Также отображается загруженность комплекса, акции и события.',
      type: 'textBlock',
    }, {
      img: screenOnboarding1,
      type: 'mediumImg',
    }, {
      img: screenOnboarding2,
      type: 'mediumImg',
    }, {
      p: 'Для акций и событий мы сделали универсальные страницы, которые клиент сможет легко заполнять',
      type: 'textBlockRight',
    }, {
      img: screenOnboarding3,
      type: 'longLowImg',
    }],
  },
  app: {
    isScreens: false,
    title: 'Приложение в сторе',
    images: [{
      img: screenApp1,
      type: 'largeImg',
    }],
  },
  nextProject: {
    name: 'Vitakit ',
    image: bannerImgVita,
    link: '/vitakit',
  },
};

export default function GremmPage() {
  const [pageData, setPageData] = React.useState(initPageData);
  const [currentSize, setCurrentSize] = React.useState(0);
  const { scroll } = useLocomotiveScroll();
  const { width } = useWindowDimensions();

  function updateCaseObject() {
    const newPageData = { ...pageData };

    newPageData.banner.image = bannerImg;
    newPageData.firstImage = projectImg;
    newPageData.mainScreens.images = [
      {
        img: screenImg1,
        type: 'largeImg',
      }, {
        img: screenImg2,
        type: 'mediumImg',
      }, {
        img: screenImg3,
        type: 'mediumImg',
      }, {
        img: screenImg4,
        type: 'largeImg',
      }];
    newPageData.restaurants.images = [{
      img: screenRestaurant1,
      type: 'largeImg',
    }, {
      img: screenRestaurant2,
      type: 'longLowImgH',
    }];
    newPageData.account.blocks = [{
      label: 'Описание',
      p: 'В профиле расположены данные пользователя, карта лояльности, оплата счёта, бронирование проходов и оплата парковки. Также есть возможность управления адресами и способами оплаты.',
      type: 'textBlock',
    }, {
      img: screenAccount1,
      type: 'mediumImg',
    }, {
      img: screenAccount2,
      type: 'largeImg',
    }, {
      img: screenAccount3,
      type: 'longLowImg',
    }, {
      img: screenAccount4,
      type: 'mediumImg',
    }, {
      label: 'Описание',
      p: 'С поддержкой можно созвониться по телефону или написать сотруднику в чате. В чате можно приложить изображения к сообщению.',
      type: 'textBlockRight',
    }];
    newPageData.loyalty.images = [{
      img: screenLoyalty1,
      type: 'largeImg',
    }];
    newPageData.onboarding.blocks = [{
      label: 'Описание',
      p: 'На главной расположены блоки с основными действиями: оплата счёта, покупка проходов, обратная связь и авторизация за бонусы. Также отображается загруженность комплекса, акции и события.',
      type: 'textBlock',
    }, {
      img: screenOnboarding1,
      type: 'mediumImg',
    }, {
      img: screenOnboarding2,
      type: 'mediumImg',
    }, {
      p: 'Для акций и событий мы сделали универсальные страницы, которые клиент сможет легко заполнять',
      type: 'textBlockRight',
    }, {
      img: screenOnboarding3,
      type: 'longLowImg',
    }];
    newPageData.app.images = [{
      img: screenApp1,
      type: 'largeImg',
    }];
    newPageData.nextProject.image = bannerImgVita;

    setPageData(newPageData);
  }

  useEffect(() => {
    if (width >= 1200) {
      if (currentSize !== 1440) {
        updateImgs();
        updateCaseObject();
        setCurrentSize(1440);
      }
    } else if (width < 1200 && width >= 900) {
      if (currentSize !== 1000) {
        updateImgs();
        updateCaseObject();
        setCurrentSize(1000);
      }
    } else if (width < 900 && width >= 600) {
      if (currentSize !== 640) {
        updateImgs();
        updateCaseObject();
        setCurrentSize(640);
      }
    } else if (width < 600) {
      if (currentSize !== 320) {
        updateImgs();
        updateCaseObject();
        setCurrentSize(320);
      }
    }
  }, [width]);

  useEffect(() => {
    if (scroll) {
      const header = document.getElementsByClassName('header')[0];
      let prevScrollpos = 0;
      scroll.on('scroll', (e) => {
        const currentScrollPos = e.scroll.y;
        if (currentScrollPos > 0) {
          header.classList.add('filled');
        }
        if (currentScrollPos - prevScrollpos > 10) {
          header.classList.add('hidden');
          prevScrollpos = currentScrollPos;
        }
        if (prevScrollpos - currentScrollPos > 10) {
          header.classList.remove('hidden');
          prevScrollpos = currentScrollPos;
        }
        if (currentScrollPos < 50) {
          header.classList.remove('filled');
        }
        if (currentScrollPos < 10) {
          header.classList.remove('hidden');
        }
      });
    }
  }, [scroll]);

  return (
    <div data-scroll-section="" className="projectPage">
      <Header color="black" />
      <ProjectBanner
        name={pageData.banner.name}
        services={pageData.banner.services}
        image={pageData.banner.image}
        buttonText={pageData.banner.buttonText}
      />
      <ProjectAbout
        text={pageData.about.text}
        tasks={pageData.about.tasks}
        year={pageData.about.year}
      />

      <div className="parallaxBlock">
        <img data-scroll="" data-scroll-speed="-1.5" alt="" src={pageData.firstImage} />
      </div>

      <MainScreens
        isScreens={pageData.mainScreens.isScreens}
        label={pageData.mainScreens.label}
        text={pageData.mainScreens.text}
        blocks={pageData.mainScreens.images}
      />

      <MainScreens
        isScreens={pageData.restaurants.isScreens}
        label={pageData.restaurants.label}
        text={pageData.restaurants.text}
        blocks={pageData.restaurants.images}
      />

      <MainScreens
        isScreens={pageData.account.isScreens}
        title={pageData.account.title}
        blocks={pageData.account.blocks}
      />

      <MainScreens
        isScreens={pageData.loyalty.isScreens}
        label={pageData.loyalty.label}
        text={pageData.loyalty.text}
        blocks={pageData.loyalty.images}
      />

      <MainScreens
        isScreens={pageData.onboarding.isScreens}
        title={pageData.onboarding.title}
        blocks={pageData.onboarding.blocks}
      />

      <MainScreens
        isScreens={pageData.app.isScreens}
        title={pageData.app.title}
        blocks={pageData.app.images}
      />

      <NextProject
        link={pageData.nextProject.link}
        image={pageData.nextProject.image}
        name={pageData.nextProject.name}
      />

      <LetsWorkTogether />
      <Footer />
    </div>
  );
}
