import React from 'react';
import './ContactsContent.css';
import fire from '../../../../shared_components/LetsWorkTogether/img/fire.svg';
import bottomArrow from '../../../../shared_components/LetsWorkTogether/img/bottomArrow.svg';
import ContactsForm from '../../atoms/ContactsForm/ContactsForm';

export default function ContactsContent() {
  return (
    <div className="contactsContent container ">
      <div data-scroll-offset="20%" data-scroll="" data-scroll-class="fadeUp-active" className="row firstLine toFadeUp">
        <div className="title">
          <div className="row">
            <div className="fireCircle">
              <img alt="" src={fire} />
            </div>
            <h2>Давайте</h2>
          </div>
          <h2>работать вместе</h2>
        </div>
        <img alt="" src={bottomArrow} />
      </div>
      <ContactsForm />
    </div>
  );
}
