import React, { useEffect } from 'react';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ProjectBanner from './atoms/ProjectBanner/ProjectBanner';
import ProjectAbout from './atoms/ProjectAbout/ProjectAbout';
import MainScreens from './atoms/MainScreens/MainScreens';
import Footer from '../../shared_components/Footer/Footer';
import LetsWorkTogether from '../../shared_components/LetsWorkTogether/LetsWorkTogether';
import NextProject from './atoms/NextProject/NextProject';
import Header from '../../shared_components/Header/Header';
import './ProjectPage.css';

import bannerImgCanadianX1600 from './img/canadian/1600/bannerPageCanadian1.jpg';
import projectImgCanadianX1600 from './img/canadian/1600/projectPageCanadian1.jpg';
import canadianScreenImg1X1600 from './img/canadian/1600/screen1.jpg';
import canadianScreenImg2X1600 from './img/canadian/1600/screen2.jpg';
import canadianScreenImg3X1600 from './img/canadian/1600/screen3.jpg';
import canadianScreenImg4X1600 from './img/canadian/1600/screen4.jpg';
import canadianScreenImg5X1600 from './img/canadian/1600/screen5.jpg';
import canadianScreenImg6X1600 from './img/canadian/1600/screen6.jpg';
import canadianScreenImg7X1600 from './img/canadian/1600/screen7.jpg';
import canadianScreenImg8X1600 from './img/canadian/1600/screen8.jpg';
import canadianBranding1X1600 from './img/canadian/1600/screenBranding1.jpg';
import canadianBranding2X1600 from './img/canadian/1600/screenBranding2.jpg';
import canadianBranding3X1600 from './img/canadian/1600/screenBranding3.jpg';
import canadianBranding4X1600 from './img/canadian/1600/screenBranding4.jpg';
import canadianBranding5X1600 from './img/canadian/1600/screenBranding5.jpg';
import bannerImgCanadianX1000 from './img/canadian/1000/bannerPageCanadian1.jpg';
import projectImgCanadianX1000 from './img/canadian/1000/projectPageCanadian1.jpg';
import canadianScreenImg1X1000 from './img/canadian/1000/screen1.jpg';
import canadianScreenImg2X1000 from './img/canadian/1000/screen2.jpg';
import canadianScreenImg3X1000 from './img/canadian/1000/screen3.jpg';
import canadianScreenImg4X1000 from './img/canadian/1000/screen4.jpg';
import canadianScreenImg5X1000 from './img/canadian/1000/screen5.jpg';
import canadianScreenImg6X1000 from './img/canadian/1000/screen6.jpg';
import canadianScreenImg7X1000 from './img/canadian/1000/screen7.jpg';
import canadianScreenImg8X1000 from './img/canadian/1000/screen8.jpg';
import canadianBranding1X1000 from './img/canadian/1000/screenBranding1.jpg';
import canadianBranding2X1000 from './img/canadian/1000/screenBranding2.jpg';
import canadianBranding3X1000 from './img/canadian/1000/screenBranding3.jpg';
import canadianBranding4X1000 from './img/canadian/1000/screenBranding4.jpg';
import canadianBranding5X1000 from './img/canadian/1000/screenBranding5.jpg';
import bannerImgCanadianX640 from './img/canadian/640/bannerPageCanadian1.jpg';
import projectImgCanadianX640 from './img/canadian/640/projectPageCanadian1.jpg';
import canadianScreenImg1X640 from './img/canadian/640/screen1.jpg';
import canadianScreenImg2X640 from './img/canadian/640/screen2.jpg';
import canadianScreenImg3X640 from './img/canadian/640/screen3.jpg';
import canadianScreenImg4X640 from './img/canadian/640/screen4.jpg';
import canadianScreenImg5X640 from './img/canadian/640/screen5.jpg';
import canadianScreenImg6X640 from './img/canadian/640/screen6.jpg';
import canadianScreenImg7X640 from './img/canadian/640/screen7.jpg';
import canadianScreenImg8X640 from './img/canadian/640/screen8.jpg';
import canadianBranding1X640 from './img/canadian/640/screenBranding1.jpg';
import canadianBranding2X640 from './img/canadian/640/screenBranding2.jpg';
import canadianBranding3X640 from './img/canadian/640/screenBranding3.jpg';
import canadianBranding4X640 from './img/canadian/640/screenBranding4.jpg';
import canadianBranding5X640 from './img/canadian/640/screenBranding5.jpg';
import bannerImgCanadianX320 from './img/canadian/320/bannerPageCanadian1.jpg';
import projectImgCanadianX320 from './img/canadian/320/projectPageCanadian1.jpg';
import canadianScreenImg1X320 from './img/canadian/320/screen1.jpg';
import canadianScreenImg2X320 from './img/canadian/320/screen2.jpg';
import canadianScreenImg3X320 from './img/canadian/320/screen3.jpg';
import canadianScreenImg4X320 from './img/canadian/320/screen4.jpg';
import canadianScreenImg5X320 from './img/canadian/320/screen5.jpg';
import canadianScreenImg6X320 from './img/canadian/320/screen6.jpg';
import canadianScreenImg7X320 from './img/canadian/320/screen7.jpg';
import canadianScreenImg8X320 from './img/canadian/320/screen8.jpg';
import canadianBranding1X320 from './img/canadian/320/screenBranding1.jpg';
import canadianBranding2X320 from './img/canadian/320/screenBranding2.jpg';
import canadianBranding3X320 from './img/canadian/320/screenBranding3.jpg';
import canadianBranding4X320 from './img/canadian/320/screenBranding4.jpg';
import canadianBranding5X320 from './img/canadian/320/screenBranding5.jpg';

import bannerImgDelayX1600 from './img/delay/1600/bannerPageDelay1.jpg';
import bannerImgDelayX1000 from './img/delay/1000/bannerPageDelay1.jpg';
import bannerImgDelayX640 from './img/delay/640/bannerPageDelay1.jpg';
import bannerImgDelayX320 from './img/delay/320/bannerPageDelay1.jpg';

let bannerImgCanadian;
let projectImgCanadian;
let canadianScreenImg1;
let canadianScreenImg2;
let canadianScreenImg3;
let canadianScreenImg4;
let canadianScreenImg5;
let canadianScreenImg6;
let canadianScreenImg7;
let canadianScreenImg8;
let canadianBranding1;
let canadianBranding2;
let canadianBranding3;
let canadianBranding4;
let canadianBranding5;
let bannerImgDelay;

function updateImgs() {
  if (window.innerWidth >= 1200) {
    bannerImgCanadian = bannerImgCanadianX1600;
    projectImgCanadian = projectImgCanadianX1600;
    canadianScreenImg1 = canadianScreenImg1X1600;
    canadianScreenImg2 = canadianScreenImg2X1600;
    canadianScreenImg3 = canadianScreenImg3X1600;
    canadianScreenImg4 = canadianScreenImg4X1600;
    canadianScreenImg5 = canadianScreenImg5X1600;
    canadianScreenImg6 = canadianScreenImg6X1600;
    canadianScreenImg7 = canadianScreenImg7X1600;
    canadianScreenImg8 = canadianScreenImg8X1600;
    canadianBranding1 = canadianBranding1X1600;
    canadianBranding2 = canadianBranding2X1600;
    canadianBranding3 = canadianBranding3X1600;
    canadianBranding4 = canadianBranding4X1600;
    canadianBranding5 = canadianBranding5X1600;
    bannerImgCanadian = bannerImgCanadianX1600;
    bannerImgDelay = bannerImgDelayX1600;
  } else if (window.innerWidth < 1200 && window.innerWidth >= 900) {
    bannerImgCanadian = bannerImgCanadianX1000;
    projectImgCanadian = projectImgCanadianX1000;
    canadianScreenImg1 = canadianScreenImg1X1000;
    canadianScreenImg2 = canadianScreenImg2X1000;
    canadianScreenImg3 = canadianScreenImg3X1000;
    canadianScreenImg4 = canadianScreenImg4X1000;
    canadianScreenImg5 = canadianScreenImg5X1000;
    canadianScreenImg6 = canadianScreenImg6X1000;
    canadianScreenImg7 = canadianScreenImg7X1000;
    canadianScreenImg8 = canadianScreenImg8X1000;
    canadianBranding1 = canadianBranding1X1000;
    canadianBranding2 = canadianBranding2X1000;
    canadianBranding3 = canadianBranding3X1000;
    canadianBranding4 = canadianBranding4X1000;
    canadianBranding5 = canadianBranding5X1000;
    bannerImgDelay = bannerImgDelayX1000;
  } else if (window.innerWidth < 900 && window.innerWidth >= 600) {
    bannerImgCanadian = bannerImgCanadianX640;
    projectImgCanadian = projectImgCanadianX640;
    canadianScreenImg1 = canadianScreenImg1X640;
    canadianScreenImg2 = canadianScreenImg2X640;
    canadianScreenImg3 = canadianScreenImg3X640;
    canadianScreenImg4 = canadianScreenImg4X640;
    canadianScreenImg5 = canadianScreenImg5X640;
    canadianScreenImg6 = canadianScreenImg6X640;
    canadianScreenImg7 = canadianScreenImg7X640;
    canadianScreenImg8 = canadianScreenImg8X640;
    canadianBranding1 = canadianBranding1X640;
    canadianBranding2 = canadianBranding2X640;
    canadianBranding3 = canadianBranding3X640;
    canadianBranding4 = canadianBranding4X640;
    canadianBranding5 = canadianBranding5X640;
    bannerImgDelay = bannerImgDelayX640;
  } else if (window.innerWidth < 600) {
    bannerImgCanadian = bannerImgCanadianX320;
    projectImgCanadian = projectImgCanadianX320;
    canadianScreenImg1 = canadianScreenImg1X320;
    canadianScreenImg2 = canadianScreenImg2X320;
    canadianScreenImg3 = canadianScreenImg3X320;
    canadianScreenImg4 = canadianScreenImg4X320;
    canadianScreenImg5 = canadianScreenImg5X320;
    canadianScreenImg6 = canadianScreenImg6X320;
    canadianScreenImg7 = canadianScreenImg7X320;
    canadianScreenImg8 = canadianScreenImg8X320;
    canadianBranding1 = canadianBranding1X320;
    canadianBranding2 = canadianBranding2X320;
    canadianBranding3 = canadianBranding3X320;
    canadianBranding4 = canadianBranding4X320;
    canadianBranding5 = canadianBranding5X320;
    bannerImgDelay = bannerImgDelayX320;
  }
}
updateImgs();

const initPageData = {
  banner: {
    name: 'Canadian international ',
    services: 'UX/UI, Разработка',
    image: bannerImgCanadian,
    buttonText: 'Проект закрыт',
  },
  about: {
    text: 'Canadian international sports & education – компания, которая помогает иммигрировать в Канаду по учебной и спортивной визам.',
    tasks: ['Создали дизайн', 'Разработали сайт'],
    year: '2021',
  },
  firstImage: projectImgCanadian,
  textWithScreens: {
    isScreens: false,
    title: 'Основные функции',
    blocks: [{
      img: canadianScreenImg1,
      type: 'largeImg',
    }, {
      img: canadianScreenImg2,
      type: 'mediumImg',
    }, {
      img: canadianScreenImg3,
      type: 'mediumImg',
    }, {
      img: canadianScreenImg4,
      type: 'longLowImg',
    }, {
      img: canadianScreenImg5,
      type: 'mediumImg',
    }, {
      img: [canadianScreenImg6, canadianScreenImg7],
      type: 'topShortLongImgs',
    }, {
      img: canadianScreenImg8,
      type: 'longLowImg',
    }],
  },
  branding: {
    isScreens: false,
    title: 'Брэндинг',
    blocks: [{
      img: canadianBranding1,
      type: 'mediumImg',
    }, {
      img: canadianBranding2,
      type: 'mediumImg',
    }, {
      img: canadianBranding3,
      type: 'mediumImg',
    }, {
      img: [canadianBranding4, canadianBranding5],
      type: 'leftBottomRightTopImgs',
    }],
  },
  nextProject: {
    name: 'Делай вещи ',
    image: bannerImgDelay,
    link: '/delay',
  },
};

export default function CanadianPage() {
  const [pageData, setPageData] = React.useState(initPageData);
  const [currentSize, setCurrentSize] = React.useState(0);
  const { scroll } = useLocomotiveScroll();
  const { width } = useWindowDimensions();

  function updateCaseObject() {
    const newPageData = { ...pageData };

    newPageData.banner.image = bannerImgCanadian;
    newPageData.firstImage = projectImgCanadian;
    newPageData.textWithScreens.blocks = [
      {
        img: canadianScreenImg1,
        type: 'largeImg',
      }, {
        img: canadianScreenImg2,
        type: 'mediumImg',
      }, {
        img: canadianScreenImg3,
        type: 'mediumImg',
      }, {
        img: canadianScreenImg4,
        type: 'longLowImg',
      }, {
        img: canadianScreenImg5,
        type: 'mediumImg',
      }, {
        img: [canadianScreenImg6, canadianScreenImg7],
        type: 'topShortLongImgs',
      }, {
        img: canadianScreenImg8,
        type: 'longLowImg',
      }];
    newPageData.branding.blocks = [
      {
        img: canadianBranding1,
        type: 'mediumImg',
      }, {
        img: canadianBranding2,
        type: 'mediumImg',
      }, {
        img: canadianBranding3,
        type: 'mediumImg',
      }, {
        img: [canadianBranding4, canadianBranding5],
        type: 'leftBottomRightTopImgs',
      }];
    newPageData.nextProject.image = bannerImgDelay;

    setPageData(newPageData);
  }

  useEffect(() => {
    if (width >= 1200) {
      if (currentSize !== 1440) {
        updateImgs();
        updateCaseObject();
        setCurrentSize(1440);
      }
    } else if (width < 1200 && width >= 900) {
      if (currentSize !== 1000) {
        updateImgs();
        updateCaseObject();
        setCurrentSize(1000);
      }
    } else if (width < 900 && width >= 600) {
      if (currentSize !== 640) {
        updateImgs();
        updateCaseObject();
        setCurrentSize(640);
      }
    } else if (width < 600) {
      if (currentSize !== 320) {
        updateImgs();
        updateCaseObject();
        setCurrentSize(320);
      }
    }
  }, [width]);

  useEffect(() => {
    if (scroll) {
      const header = document.getElementsByClassName('header')[0];
      let prevScrollpos = 0;
      scroll.on('scroll', (e) => {
        const currentScrollPos = e.scroll.y;
        if (currentScrollPos > 0) {
          header.classList.add('filled');
        }
        if (currentScrollPos - prevScrollpos > 10) {
          header.classList.add('hidden');
          prevScrollpos = currentScrollPos;
        }
        if (prevScrollpos - currentScrollPos > 10) {
          header.classList.remove('hidden');
          prevScrollpos = currentScrollPos;
        }
        if (currentScrollPos < 50) {
          header.classList.remove('filled');
        }
        if (currentScrollPos < 10) {
          header.classList.remove('hidden');
        }
      });
    }
  }, [scroll]);

  return (
    <div data-scroll-section="" className="projectPage">
      <Header color="black" />
      <ProjectBanner
        name={pageData.banner.name}
        services={pageData.banner.services}
        image={pageData.banner.image}
        buttonText={pageData.banner.buttonText}
        link={pageData.banner.buttonLink}
      />
      <ProjectAbout
        text={pageData.about.text}
        tasks={pageData.about.tasks}
        year={pageData.about.year}
      />

      <div className="parallaxBlock">
        <img data-scroll="" data-scroll-speed="-1.5" alt="" src={pageData.firstImage} />
      </div>

      <MainScreens
        isScreens={pageData.textWithScreens.isScreens}
        title={pageData.textWithScreens.title}
        blocks={pageData.textWithScreens.blocks}
      />
      <MainScreens
        isScreens={pageData.branding.isScreens}
        title={pageData.branding.title}
        blocks={pageData.branding.blocks}
      />

      <NextProject
        link={pageData.nextProject.link}
        image={pageData.nextProject.image}
        name={pageData.nextProject.name}
      />

      <LetsWorkTogether />
      <Footer />
    </div>
  );
}
