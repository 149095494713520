import React from 'react';
import './LetsWorkTogether.css';
import { Link } from 'react-router-dom';
import fire from './img/fire.svg';
import bottomArrow from './img/bottomArrow.svg';

export default function LetsWorkTogether() {
  // let right = 0;
  //
  //
  // let prevScroll;
  // document.onscroll = () =>{
  //     let scrollHeight = window.scrollY;
  //     let screenWidth = window.innerWidth;
  //     let offset;
  //
  //     let holder = document.getElementsByClassName('letsWorkTogether')[0];
  //     if(holder){
  //         offset = holder.offsetTop
  //     }
  //     console.log('scrollHeight: ' + scrollHeight + ' ; ' + 'offset: ' + offset + ' ; ')
  //     if(scrollHeight > offset){
  //         let element = document.getElementsByClassName('absHolder')[0];
  //         if(scrollHeight > prevScroll){
  //             if(right < 1000){
  //                 right+=100;
  //             }
  //             element.style.left = right + 'px';
  //         }
  //         if(scrollHeight < prevScroll){
  //             if(right > 0){
  //                 right-=100;
  //             }
  //             element.style.left = right + 'px';
  //         }
  //     }
  //
  //     // if(scrollHeight + 500 < offset){
  //     //     right = 300;
  //     // }
  //
  //
  //     prevScroll = scrollHeight;
  //
  //
  //
  // }
  return (
    <div data-scroll="" className="letsWorkTogether">
      <div className="container">
        <div data-scroll-offset="20%" data-scroll="" data-scroll-class="fadeUp-active" className="row firstLine toFadeUp">
          <div className="title">
            <div className="row">
              <div className="fireCircle">
                <img alt="" src={fire} />
              </div>
              <h2>Давайте</h2>
            </div>
            <h2>работать вместе</h2>
          </div>
          <img alt="" src={bottomArrow} />
        </div>
        <div className="secondLine">
          <div data-scroll-offset="-50%, 0" data-scroll-position="bottom" data-scroll="" data-scroll-speed="-2" data-scroll-direction="horizontal" className="absHolder">
            <Link to="/contacts">
              <button type="button" data-strength-text="50" data-strength="100" className="animatedCircleButton buttonScroller magnetic">
                <p>Написать нам</p>
                <div style={{ backgroundColor: '#569F59' }} className="buttonBack btn-fill" />
              </button>
            </Link>
          </div>

        </div>
        <div data-scroll-offset="-60%" data-scroll="" data-scroll-class="fadeUp-active" className="thirdLine row toFadeUp">
          <a href="mailto:contact@polydev.io" data-strength-text="15" data-strength="35" className="filledFromLeft magnetic">
            <span>contact@polydev.io</span>
            <div className="buttonBackLong" />
          </a>
          <a href="tel:+7 (995)-987-05-98" data-strength-text="15" data-strength="35" className="filledFromLeft magnetic">
            <span>+7 (995)-987-05-98</span>
            <div className="buttonBackLong" />
          </a>
        </div>
      </div>
    </div>
  );
}
