import React, { useEffect } from 'react';
import Marquee from 'react-fast-marquee';
import './NextProject.css';
import { Link } from 'react-router-dom';
import { Cursor } from 'react-creative-cursor';
import arrowRight from './img/arrowRight.svg';
import FilledFromLeft from '../../../../shared_components/FilledFromLeft/FilledFromLeft';

export default function NextProject(props) {
  const [marqueeSpeed, setMarqueeSpeed] = React.useState(75);

  useEffect(() => {
    if (window.innerWidth > 1199) {
      setMarqueeSpeed(75);
    } else if (window.innerWidth <= 1199 && window.innerWidth > 899) {
      setMarqueeSpeed(75);
    } else if (window.innerWidth <= 899) {
      setMarqueeSpeed(35);
    }
  }, [window.innerWidth]);

  return (
    <div className="nextProject">
      <Link to={props.link}>
        <div data-scroll-offset="20%" data-scroll="" data-scroll-class="fadeUp-active" className="container toFadeUp">
          <div className="buttonNext">
            <Link to={props.link}>
              <p>Следующий проект</p>
              <img alt="" src={arrowRight} />
            </Link>
          </div>
          <Link to="/projects">
            <FilledFromLeft name="Все проекты" />
          </Link>
        </div>
        <Cursor cursorSize={0} isGelly={false} />
        <img data-strength-text="50" data-strength="100" alt="" className="nextImg magnetic" src={props.image} />
        <div className="runningLine">
          <Marquee className="runningLine__block" speed={marqueeSpeed} gradient={false}>
            &nbsp;
            {`— ${props.name}`.repeat(3)}
          </Marquee>
        </div>
      </Link>
    </div>
  );
}
