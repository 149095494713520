import React from 'react';
import { Link } from 'react-router-dom';
import ProjectCard from '../ProjectCard/ProjectCard';
import './ProjectsList.css';
import FilledFromLeft from '../FilledFromLeft/FilledFromLeft';
import { projects } from './projects';

export default function ProjectsList(props) {
  if (props.isShorted) {
    return (
      <div className="projects container">
        <div data-scroll-offset="20%" data-scroll="" data-scroll-class="fadeUp-active" className="row headerLine toFadeUp">
          <p>Проекты</p>
          <p>(8)</p>
        </div>
        <div className="content row">
          {projects.slice(0, 4).map((item, id) => {
            const type = (id + 1) % 2 === 1 ? 'even' : 'odd';

            return (
              <Link to={item.link} key={item.title}>
                <ProjectCard
                  img={item.img}
                  description={item.description}
                  title={item.title}
                  type={type}
                />
              </Link>
            );
          })}
        </div>
        <Link to="/projects">
          <FilledFromLeft name="Все проекты" />
        </Link>
      </div>
    );
  }
  return (
    <div className="projects container">
      <div className="row headerLine">
        <p>Проекты</p>
        <p>(8)</p>
      </div>
      <div className="content row">
        {projects.map((item, id) => {
          const type = (id + 1) % 2 === 1 ? 'even' : 'odd';

          return (
            <Link to={item.link} key={item.title}>
              <ProjectCard
                img={item.img}
                description={item.description}
                title={item.title}
                type={type}
              />
            </Link>
          );
        })}
      </div>
    </div>
  );
}
