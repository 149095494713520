export default async function sendMail(formData) {
  try {
    const response = await fetch('/api/sendMail.php', {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      return null;
    }

    return response.json();
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    return null;
  }
}
