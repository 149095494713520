import React, { useEffect } from 'react';
import './MainScreens.css';
import { useLocation } from 'react-router-dom';

export default function MainScreens(props) {
  const location = useLocation();
  const [loc, setLoc] = React.useState('');

  useEffect(() => {
    switch (location.pathname) {
      case '/swiftGlobal':
        setLoc('swift');
        break;
      case '/vitakit':
        setLoc('vita');
        break;
      case '/megacvet':
        setLoc('vita megacvet');
        break;
      case '/gremm':
        setLoc('vita megacvet gremm');
        break;
      case '/vitacarta':
        setLoc('vitacarta');
        break;
      case '/canadian':
        setLoc('canadian');
        break;
      case '/delay':
        setLoc('delay');
        break;
      case '/dsp':
        setLoc('dsp');
        break;
      default:
        setLoc('');
        break;
    }
  }, []);

  return (
    <div className={`mainScreens ${loc}`}>
      <div className="container">
        {props.isScreens ? (
          <label data-scroll-offset="20%" data-scroll="" data-scroll-class="fadeUp-active" className="toFadeUp">{props.label}</label>
        ) : (
          <h3 data-scroll-offset="20%" data-scroll="" data-scroll-class="fadeUp-active" className="toFadeUp">{props.title}</h3>
        )}
        {props.isScreens && (
          <p data-scroll-offset="20%" data-scroll="" data-scroll-class="fadeUp-active" className="toFadeUp">{props.text}</p>
        )}

        <div className="row">
          {props.blocks.map((block, blockOrderId) => {
            const screenWidth = window.innerWidth;
            if (block.type === 'largeImg') {
              return (
                <div key={blockOrderId} className="largeImg">
                  <img src={block.img} alt="" />
                </div>
              );
            }
            if (block.type === 'mediumImg') {
              return (
                <div key={blockOrderId} className="mediumImg">
                  <img src={block.img} alt="" />
                </div>
              );
            }
            if (block.type === 'img75') {
              return (
                <div key={blockOrderId} className="img75">
                  <img src={block.img} alt="" />
                </div>
              );
            }
            if (block.type === 'smallImgBottom') {
              if (screenWidth < 1199) {
                return (
                  <div key={blockOrderId} className="mediumImg">
                    <img src={block.img} alt="" />
                  </div>
                );
              }
              return (
                <div key={blockOrderId} className="smallImg bottom">
                  <img src={block.img} alt="" />
                </div>
              );
            }
            if (block.type === 'smallImg') {
              return (
                <div key={blockOrderId} className="smallImg top">
                  <img src={block.img} alt="" />
                </div>
              );
            }
            if (block.type === 'longLowImg') {
              return (
                <div key={blockOrderId} className="longLowImg">
                  <img src={block.img} alt="" />
                </div>
              );
            }
            if (block.type === 'longLowImgH') {
              return (
                <div key={blockOrderId} className="longLowImgH">
                  <img src={block.img} alt="" />
                </div>
              );
            }
            if (block.type === 'leftTopRightBottomImgs') {
              if (screenWidth < 1199) {
                return (
                  <>
                    <div key={blockOrderId} className="mediumImg">
                      <img src={block.img[0]} alt="" />
                    </div>
                    <div key={blockOrderId} className="mediumImg">
                      <img src={block.img[1]} alt="" />
                    </div>
                  </>
                );
              }
              return (
                <div key={blockOrderId} className="leftTopRightBottomImgs">
                  <img src={block.img[0]} alt="" />
                  <img src={block.img[1]} alt="" />
                </div>
              );
            }
            if (block.type === 'leftBottomRightTopImgs') {
              return (
                <div key={blockOrderId} className="leftBottomRightTopImgs">
                  <img src={block.img[0]} alt="" />
                  <img src={block.img[1]} alt="" />
                </div>
              );
            }
            if (block.type === 'leftTopRightBottomBigImgs') {
              return (
                <div key={blockOrderId} className="leftTopRightBottomBigImgs">
                  <img src={block.img[0]} alt="" />
                  <img src={block.img[1]} alt="" />
                </div>
              );
            }
            if (block.type === 'mediumShortLongImgs') {
              if (screenWidth < 1199) {
                return (
                  <>
                    <div key={blockOrderId} className="mediumImg">
                      <img src={block.img[0]} alt="" />
                    </div>
                    <div key={blockOrderId} className="mediumImg">
                      <img src={block.img[1]} alt="" />
                    </div>
                  </>
                );
              }
              return (
                <div key={blockOrderId} className="mediumShortLongImgs">
                  <img src={block.img[0]} alt="" />
                  <img src={block.img[1]} alt="" />
                </div>
              );
            }
            if (block.type === 'topShortLongImgs') {
              if (screenWidth < 1199) {
                return (
                  <>
                    <div key={blockOrderId} className="mediumImg">
                      <img src={block.img[0]} alt="" />
                    </div>
                    <div key={blockOrderId} className="mediumImg">
                      <img src={block.img[1]} alt="" />
                    </div>
                  </>
                );
              }
              return (
                <div key={blockOrderId} className="mediumShortLongImgs topShortLongImgs">
                  <img src={block.img[0]} alt="" />
                  <img src={block.img[1]} alt="" />
                </div>
              );
            }
            if (block.type === 'textBlock') {
              return (
                <div key={blockOrderId} className="textBlock">
                  {block.label && <label>{block.label}</label>}
                  <p>{block.p}</p>
                </div>
              );
            }

            if (block.type === 'textBlockRight') {
              return (
                <div key={blockOrderId} className="textBlock textBlockRight">
                  {block.label && <label>{block.label}</label>}
                  <p>{block.p}</p>
                </div>
              );
            }
            if (block.type === 'smallMediumRow') {
              if (screenWidth < 1199) {
                return (
                  <>
                    <div key={blockOrderId} className="mediumImg">
                      <img src={block.img[0]} alt="" />
                    </div>
                    <div key={blockOrderId} className="img75">
                      <img src={block.img[1]} alt="" />
                    </div>
                  </>
                );
              }
              return (
                <div key={blockOrderId} className="smallMediumRow">
                  <img src={block.img[0]} alt="" />
                  <img src={block.img[1]} alt="" />
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}
