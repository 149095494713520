import project1 from './img/project1Img.jpg';
import project2 from './img/project2Img.jpg';
import project3 from './img/project3Img.jpg';
import project4 from './img/project4Img.jpg';
import project5 from './img/project5Img.jpg';
import megacolor from './img/megacolor.jpg';
import vitacard from './img/vitacard.jpg';
import gremm from './img/gremm.jpg';

// eslint-disable-next-line import/prefer-default-export
export const projects = [{
  img: project1,
  title: 'Vita kit',
  description: 'Разработка, поддержка',
  link: '/vitakit',
}, {
  img: project2,
  title: 'Swift Global',
  description: 'Разработка, UI/UX, брендинг, поддержка',
  link: '/swiftGlobal',
}, {
  img: megacolor,
  title: 'Мегацвет24',
  description: 'Разработка, UI/UX',
  link: '/megacvet',
}, {
  img: vitacard,
  title: 'Витакарта',
  description: 'Разработка',
  link: '/vitacarta',
}, {
  img: project4,
  title: 'DSP',
  description: 'Разработка',
  link: '/dsp',
}, {
  img: project5,
  title: 'Canadian international',
  description: 'UX/UI, Разработка, Брендинг',
  link: '/canadian',
}, {
  img: project3,
  title: 'Делай вещи',
  description: 'Разработка',
  link: '/delay',
}, {
  img: gremm,
  title: 'Грэмм групп',
  description: 'UX/UI-дизайн, Разработка',
  link: '/gremm',
}];
