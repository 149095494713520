import React from 'react';
import './RunningPageIcons.css';
import { useLocation } from 'react-router-dom';
import icon1 from './img/runningIcons1Img.jpg';
import icon2 from './img/runningIcons2Img.jpg';
import icon3 from './img/runningIcons3Img.jpg';
import icon4 from './img/runningIcons4Img.jpg';
import icon5 from './img/runningIcons5Img.jpg';
import icon6 from './img/runningIcons6Img.jpg';
import RunningPageLine from '../RunningPageLine/RunningPageLine';

const firstLineImages = [icon1, icon2, icon5];
const secondLineImages = [icon4, icon3, icon6];
export default function RunningPageIcons() {
  const location = useLocation();
  if (location.pathname === '/projects') {
    return (
      <div className="runningPageIcons padding">
        <div data-scroll-offset="20%" data-scroll="" data-scroll-class="fadeUp-active" className="toFadeUp">
          <div className="greyLine" />
          <RunningPageLine action="/about" margin text="О нас" imgs={firstLineImages} />
          <div className="greyLine" />
        </div>
      </div>
    );
  } if (location.pathname === '/about') {
    return (
      <div className="runningPageIcons padding">
        <div data-scroll-offset="20%" data-scroll="" data-scroll-class="fadeUp-active" className="toFadeUp">
          <div className="greyLine container" />
          <RunningPageLine action="/projects" text="Проекты" imgs={secondLineImages} />
          <div className="greyLine container" />
        </div>
      </div>
    );
  }
  return (
    <div className="runningPageIcons">
      <div data-scroll-offset="20%" data-scroll="" data-scroll-class="fadeUp-active" className="toFadeUp">
        <div className="greyLine container" />
        <RunningPageLine action="/about" margin text="О нас" imgs={firstLineImages} />
        <div className="greyLine container" />
      </div>
      <div data-scroll-offset="20%" data-scroll="" data-scroll-class="fadeUp-active" className="toFadeUp">
        <RunningPageLine action="/projects" text="Проекты" imgs={secondLineImages} />
        <div className="greyLine container" />
      </div>

    </div>
  );
}
