import React, { useEffect } from 'react';
import './ProjectBanner.css';
import { Cursor } from 'react-creative-cursor';
import anime from 'animejs';
import Marquee from 'react-fast-marquee';

export default function ProjectBanner(props) {
  const [marqueeSpeed, setMarqueeSpeed] = React.useState(75);

  useEffect(() => {
    if (window.innerWidth > 1199) {
      setMarqueeSpeed(75);
    } else if (window.innerWidth <= 1199 && window.innerWidth > 899) {
      setMarqueeSpeed(75);
    } else if (window.innerWidth <= 899) {
      setMarqueeSpeed(35);
    }
  }, [window.innerWidth]);

  function showBanner() {
    const animation = anime.timeline({
      easing: 'linear',
      duration: 700,
    });
    animation
      .add({
        targets: ['.runningLine', '.servicesHolder', '.animatedCircleButton'],
        delay: 500,
        duration: 700,
        opacity: 1,
      })
      .add({
        targets: '.projectBannerImg',
        opacity: 1,
      });
  }

  useEffect(() => {
    showBanner();
  }, []);

  const onClickLinkHandler = () => {
    if (props.link) {
      window.open(props.link, '_blank');
    } else if (props.linkIOS && props.linkAndroid) {
      const link = /Android/.test(navigator.userAgent) ? props.linkAndroid : props.linkIOS;

      window.open(link, '_blank');
    }
  };

  return (
    <div className="projectBanner">
      <div className="container">
        <div className="servicesHolder">
          <label>Услуги</label>
          <p>{props.services}</p>
        </div>
        <Cursor cursorSize={0} isGelly={false} />
        <button
          type="button"
          onClick={onClickLinkHandler}
          data-strength-text="50"
          data-strength="100"
          className="animatedCircleButton magnetic"
        >
          <p>{props.buttonText}</p>
          <div className="btn-fill buttonBack" />
        </button>
      </div>

      <div className="runningLine">
        <Marquee className="runningLine__block" speed={marqueeSpeed} gradient={false}>
          &nbsp;
          {`— ${props.name}`.repeat(3)}
        </Marquee>
      </div>
      <img alt="" data-strength-text="50" data-strength="100" className="projectBannerImg magnetic" src={props.image} />
    </div>
  );
}
