import React, { useEffect } from 'react';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ProjectBanner from './atoms/ProjectBanner/ProjectBanner';
import ProjectAbout from './atoms/ProjectAbout/ProjectAbout';
import MainScreens from './atoms/MainScreens/MainScreens';
import Footer from '../../shared_components/Footer/Footer';
import LetsWorkTogether from '../../shared_components/LetsWorkTogether/LetsWorkTogether';
import NextProject from './atoms/NextProject/NextProject';
import Header from '../../shared_components/Header/Header';
import './ProjectPage.css';

// 1600px
import bannerImgVitaX1600 from './img/vitakit/1600/projectBannerImg.jpg';
import projectImgVitaX1600 from './img/vitakit/1600/projectPageVita1.jpg';
import vitaScreenImg1X1600 from './img/vitakit/1600/screen1.jpg';
import vitaScreenImg2X1600 from './img/vitakit/1600/screen2.jpg';
import vitaScreenImg3X1600 from './img/vitakit/1600/screen3.jpg';
import vitaScreenImg4X1600 from './img/vitakit/1600/screen4.jpg';
import vitaScreenRecipes1X1600 from './img/vitakit/1600/screenRecipes1.jpg';
import vitaScreenRecipes2X1600 from './img/vitakit/1600/screenRecipes2.jpg';
import vitaScreenBranding1X1600 from './img/vitakit/1600/screenBranding1.jpg';
import vitaScreenBranding2X1600 from './img/vitakit/1600/screenBranding2.jpg';
import vitaScreenBranding3X1600 from './img/vitakit/1600/screenBranding3.jpg';
import vitaScreenBranding4X1600 from './img/vitakit/1600/screenBranding4.jpg';
import bannerImgSwiftX1600 from './img/swift/1600/projectSwiftBannerImg.jpg';
// 1000px
import bannerImgVitaX1000 from './img/vitakit/1000/projectBannerImg.jpg';
import projectImgVitaX1000 from './img/vitakit/1000/projectPageVita1.jpg';
import vitaScreenImg1X1000 from './img/vitakit/1000/screen1.jpg';
import vitaScreenImg2X1000 from './img/vitakit/1000/screen2.jpg';
import vitaScreenImg3X1000 from './img/vitakit/1000/screen3.jpg';
import vitaScreenImg4X1000 from './img/vitakit/1000/screen4.jpg';
import vitaScreenRecipes1X1000 from './img/vitakit/1000/screenRecipes1.jpg';
import vitaScreenRecipes2X1000 from './img/vitakit/1000/screenRecipes2.jpg';
import vitaScreenBranding1X1000 from './img/vitakit/1000/screenBranding1.jpg';
import vitaScreenBranding2X1000 from './img/vitakit/1000/screenBranding2.jpg';
import vitaScreenBranding3X1000 from './img/vitakit/1000/screenBranding3.jpg';
import vitaScreenBranding4X1000 from './img/vitakit/1000/screenBranding4.jpg';
import bannerImgSwiftX1000 from './img/swift/1000/projectSwiftBannerImg.jpg';
// 640px
import bannerImgVitaX640 from './img/vitakit/640/projectBannerImg.jpg';
import projectImgVitaX640 from './img/vitakit/640/projectPageVita1.jpg';
import vitaScreenImg1X640 from './img/vitakit/640/screen1.jpg';
import vitaScreenImg2X640 from './img/vitakit/640/screen2.jpg';
import vitaScreenImg3X640 from './img/vitakit/640/screen3.jpg';
import vitaScreenImg4X640 from './img/vitakit/640/screen4.jpg';
import vitaScreenRecipes1X640 from './img/vitakit/640/screenRecipes1.jpg';
import vitaScreenRecipes2X640 from './img/vitakit/640/screenRecipes2.jpg';
import vitaScreenBranding1X640 from './img/vitakit/640/screenBranding1.jpg';
import vitaScreenBranding2X640 from './img/vitakit/640/screenBranding2.jpg';
import vitaScreenBranding3X640 from './img/vitakit/640/screenBranding3.jpg';
import vitaScreenBranding4X640 from './img/vitakit/640/screenBranding4.jpg';
import bannerImgSwiftX640 from './img/swift/640/projectSwiftBannerImg.jpg';
// 320px
import bannerImgVitaX320 from './img/vitakit/320/projectBannerImg.jpg';
import projectImgVitaX320 from './img/vitakit/320/projectPageVita1.jpg';
import vitaScreenImg1X320 from './img/vitakit/320/screen1.jpg';
import vitaScreenImg2X320 from './img/vitakit/320/screen2.jpg';
import vitaScreenImg3X320 from './img/vitakit/320/screen3.jpg';
import vitaScreenImg4X320 from './img/vitakit/320/screen4.jpg';
import vitaScreenRecipes1X320 from './img/vitakit/320/screenRecipes1.jpg';
import vitaScreenRecipes2X320 from './img/vitakit/320/screenRecipes2.jpg';
import vitaScreenBranding1X320 from './img/vitakit/320/screenBranding1.jpg';
import vitaScreenBranding2X320 from './img/vitakit/320/screenBranding2.jpg';
import vitaScreenBranding3X320 from './img/vitakit/320/screenBranding3.jpg';
import vitaScreenBranding4X320 from './img/vitakit/320/screenBranding4.jpg';
import bannerImgSwiftX320 from './img/swift/320/projectSwiftBannerImg.jpg';

let bannerImgVita;
let projectImgVita;
let vitaScreenImg1;
let vitaScreenImg2;
let vitaScreenImg3;
let vitaScreenImg4;
let vitaScreenRecipes1;
let vitaScreenRecipes2;
let vitaScreenBranding1;
let vitaScreenBranding2;
let vitaScreenBranding3;
let vitaScreenBranding4;
let bannerImgSwift;

function updateImgs() {
  if (window.innerWidth >= 1200) {
    bannerImgVita = bannerImgVitaX1600;
    projectImgVita = projectImgVitaX1600;
    vitaScreenImg1 = vitaScreenImg1X1600;
    vitaScreenImg2 = vitaScreenImg2X1600;
    vitaScreenImg3 = vitaScreenImg3X1600;
    vitaScreenImg4 = vitaScreenImg4X1600;
    vitaScreenRecipes1 = vitaScreenRecipes1X1600;
    vitaScreenRecipes2 = vitaScreenRecipes2X1600;
    vitaScreenBranding1 = vitaScreenBranding1X1600;
    vitaScreenBranding2 = vitaScreenBranding2X1600;
    vitaScreenBranding3 = vitaScreenBranding3X1600;
    vitaScreenBranding4 = vitaScreenBranding4X1600;
    bannerImgSwift = bannerImgSwiftX1600;
  } else if (window.innerWidth < 1200 && window.innerWidth >= 900) {
    bannerImgVita = bannerImgVitaX1000;
    projectImgVita = projectImgVitaX1000;
    vitaScreenImg1 = vitaScreenImg1X1000;
    vitaScreenImg2 = vitaScreenImg2X1000;
    vitaScreenImg3 = vitaScreenImg3X1000;
    vitaScreenImg4 = vitaScreenImg4X1000;
    vitaScreenRecipes1 = vitaScreenRecipes1X1000;
    vitaScreenRecipes2 = vitaScreenRecipes2X1000;
    vitaScreenBranding1 = vitaScreenBranding1X1000;
    vitaScreenBranding2 = vitaScreenBranding2X1000;
    vitaScreenBranding3 = vitaScreenBranding3X1000;
    vitaScreenBranding4 = vitaScreenBranding4X1000;
    bannerImgSwift = bannerImgSwiftX1000;
  } else if (window.innerWidth < 900 && window.innerWidth >= 600) {
    bannerImgVita = bannerImgVitaX640;
    projectImgVita = projectImgVitaX640;
    vitaScreenImg1 = vitaScreenImg1X640;
    vitaScreenImg2 = vitaScreenImg2X640;
    vitaScreenImg3 = vitaScreenImg3X640;
    vitaScreenImg4 = vitaScreenImg4X640;
    vitaScreenRecipes1 = vitaScreenRecipes1X640;
    vitaScreenRecipes2 = vitaScreenRecipes2X640;
    vitaScreenBranding1 = vitaScreenBranding1X640;
    vitaScreenBranding2 = vitaScreenBranding2X640;
    vitaScreenBranding3 = vitaScreenBranding3X640;
    vitaScreenBranding4 = vitaScreenBranding4X640;
    bannerImgSwift = bannerImgSwiftX640;
  } else if (window.innerWidth < 600) {
    bannerImgVita = bannerImgVitaX320;
    projectImgVita = projectImgVitaX320;
    vitaScreenImg1 = vitaScreenImg1X320;
    vitaScreenImg2 = vitaScreenImg2X320;
    vitaScreenImg3 = vitaScreenImg3X320;
    vitaScreenImg4 = vitaScreenImg4X320;
    vitaScreenRecipes1 = vitaScreenRecipes1X320;
    vitaScreenRecipes2 = vitaScreenRecipes2X320;
    vitaScreenBranding1 = vitaScreenBranding1X320;
    vitaScreenBranding2 = vitaScreenBranding2X320;
    vitaScreenBranding3 = vitaScreenBranding3X320;
    vitaScreenBranding4 = vitaScreenBranding4X320;
    bannerImgSwift = bannerImgSwiftX320;
  }
}
updateImgs();

const initPageData = {
  banner: {
    name: 'Vitakit ',
    services: 'Разработка, Поддержка',
    image: bannerImgVita,
    buttonText: 'Посетить сайт',
    buttonLink: 'https://vitakit.ru',
  },
  about: {
    text: 'VitaKit - это сервис для упрощения процесса приобретения выписанных врачом рецептов.',
    tasks: ['Разработали приложение', 'Создали дизайн MVP', 'Сделали MVP-версию серверной части'],
    year: '2021',
  },
  firstImage: projectImgVita,
  mainScreens: {
    isScreens: true,
    label: 'Основные экраны',
    text: 'Мы сконцентрировали внимание пользователя на четырёх основных экранах. В лекарствах можно увидеть список доступных медикаментов и добавить их в корзину.',
    images: [{
      img: vitaScreenImg1,
      type: 'largeImg',
    }, {
      img: vitaScreenImg2,
      type: 'mediumImg',
    }, {
      img: vitaScreenImg3,
      type: 'mediumImg',
    }, {
      img: vitaScreenImg4,
      type: 'longLowImg',
    }],
  },
  textWithScreens: {
    isScreens: false,
    title: 'Рецепты',
    blocks: [{
      label: 'Описание функционала',
      p: 'Приложение предоставляет централизованную систему \nс выписанными рецептами. Ползователь может проверить информацию о рецепте, \nего актуальность, \nтип и проверить наличие в аптеках.',
      type: 'textBlock',
    }, {
      img: vitaScreenRecipes1,
      type: 'mediumImg',
    }, {
      img: vitaScreenRecipes2,
      type: 'largeImg',
    }],
  },
  branding: {
    isScreens: false,
    title: 'Брэндинг',
    blocks: [{
      img: vitaScreenBranding1,
      type: 'longLowImg',
    }, {
      img: vitaScreenBranding2,
      type: 'mediumImg',
    }, {
      img: [vitaScreenBranding3, vitaScreenBranding4],
      type: 'leftBottomRightTopImgs',
    }],
  },
  nextProject: {
    name: 'Swift Global ',
    image: bannerImgSwift,
    link: '/swiftGlobal',
  },
};

export default function VitaPage() {
  const [pageData, setPageData] = React.useState(initPageData);
  const [currentSize, setCurrentSize] = React.useState(0);
  const { scroll } = useLocomotiveScroll();
  const { width } = useWindowDimensions();

  function updateCaseObject() {
    const newPageData = { ...pageData };

    newPageData.banner.image = bannerImgVita;
    newPageData.firstImage = projectImgVita;
    newPageData.mainScreens.images = [
      {
        img: vitaScreenImg1,
        type: 'largeImg',
      }, {
        img: vitaScreenImg2,
        type: 'mediumImg',
      }, {
        img: vitaScreenImg3,
        type: 'mediumImg',
      }, {
        img: vitaScreenImg4,
        type: 'longLowImg',
      }];
    newPageData.textWithScreens.blocks = [{
      label: 'Описание функционала',
      p: 'Приложение предоставляет централизованную систему \nс выписанными рецептами. Ползователь может проверить информацию о рецепте, \nего актуальность, \nтип и проверить наличие в аптеках.',
      type: 'textBlock',
    }, {
      img: vitaScreenRecipes1,
      type: 'mediumImg',
    }, {
      img: vitaScreenRecipes2,
      type: 'largeImg',
    }];
    newPageData.branding.blocks = [
      {
        img: vitaScreenBranding1,
        type: 'longLowImg',
      }, {
        img: vitaScreenBranding2,
        type: 'mediumImg',
      }, {
        img: [vitaScreenBranding3, vitaScreenBranding4],
        type: 'leftBottomRightTopImgs',
      }];
    newPageData.nextProject.image = bannerImgSwift;

    setPageData(newPageData);
  }

  useEffect(() => {
    if (width >= 1200) {
      if (currentSize !== 1440) {
        updateImgs();
        updateCaseObject();
        setCurrentSize(1440);
      }
    } else if (width < 1200 && width >= 900) {
      if (currentSize !== 1000) {
        updateImgs();
        updateCaseObject();
        setCurrentSize(1000);
      }
    } else if (width < 900 && width >= 600) {
      if (currentSize !== 640) {
        updateImgs();
        updateCaseObject();
        setCurrentSize(640);
      }
    } else if (width < 600) {
      if (currentSize !== 320) {
        updateImgs();
        updateCaseObject();
        setCurrentSize(320);
      }
    }
  }, [width]);

  useEffect(() => {
    if (scroll) {
      const header = document.getElementsByClassName('header')[0];
      let prevScrollpos = 0;
      scroll.on('scroll', (e) => {
        const currentScrollPos = e.scroll.y;
        if (currentScrollPos > 0) {
          header.classList.add('filled');
        }
        if (currentScrollPos - prevScrollpos > 10) {
          header.classList.add('hidden');
          prevScrollpos = currentScrollPos;
        }
        if (prevScrollpos - currentScrollPos > 10) {
          header.classList.remove('hidden');
          prevScrollpos = currentScrollPos;
        }
        if (currentScrollPos < 50) {
          header.classList.remove('filled');
        }
        if (currentScrollPos < 10) {
          header.classList.remove('hidden');
        }
      });
    }
  }, [scroll]);

  return (
    <div data-scroll-section="" className="projectPage">
      <Header color="black" />
      <ProjectBanner
        name={pageData.banner.name}
        services={pageData.banner.services}
        image={pageData.banner.image}
        buttonText={pageData.banner.buttonText}
        link={pageData.banner.buttonLink}
      />
      <ProjectAbout
        text={pageData.about.text}
        tasks={pageData.about.tasks}
        year={pageData.about.year}
      />

      <div className="parallaxBlock">
        <img data-scroll="" data-scroll-speed="-1.5" alt="" src={pageData.firstImage} />
      </div>

      <MainScreens
        isScreens={pageData.mainScreens.isScreens}
        label={pageData.mainScreens.label}
        text={pageData.mainScreens.text}
        blocks={pageData.mainScreens.images}
      />
      <MainScreens
        isScreens={pageData.textWithScreens.isScreens}
        title={pageData.textWithScreens.title}
        blocks={pageData.textWithScreens.blocks}
      />
      <MainScreens
        isScreens={pageData.branding.isScreens}
        title={pageData.branding.title}
        blocks={pageData.branding.blocks}
      />

      <NextProject
        link={pageData.nextProject.link}
        image={pageData.nextProject.image}
        name={pageData.nextProject.name}
      />

      <LetsWorkTogether />
      <Footer />
    </div>
  );
}
