import React, { useEffect } from 'react';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ProjectBanner from './atoms/ProjectBanner/ProjectBanner';
import ProjectAbout from './atoms/ProjectAbout/ProjectAbout';
import MainScreens from './atoms/MainScreens/MainScreens';
import Footer from '../../shared_components/Footer/Footer';
import LetsWorkTogether from '../../shared_components/LetsWorkTogether/LetsWorkTogether';
import NextProject from './atoms/NextProject/NextProject';
import Header from '../../shared_components/Header/Header';
import './ProjectPage.css';

// 1600px
import projectImgSwiftX1600 from './img/swift/1600/projectPageSwift1.jpg';
import bannerImgSwiftX1600 from './img/swift/1600/projectSwiftBannerImg.jpg';
import swiftScreenImg1X1600 from './img/swift/1600/screen1.jpg';
import swiftScreenImg2X1600 from './img/swift/1600/screen2.jpg';
import swiftScreenImg3x1X1600 from './img/swift/1600/screen3-1.jpg';
import swiftScreenImg3x2X1600 from './img/swift/1600/screen3-2.jpg';
import swiftScreenImg4X1600 from './img/swift/1600/screen4.jpg';
import swiftScreenImg5x1X1600 from './img/swift/1600/screen5-1.jpg';
import swiftScreenImg5x2X1600 from './img/swift/1600/screen5-2.jpg';
import swiftScreenRecipes1X1600 from './img/swift/1600/screenRecipes1.jpg';
import swiftScreenRecipes2X1600 from './img/swift/1600/screenRecipes2.jpg';
import swiftScreenRecipes3X1600 from './img/swift/1600/screenRecipes3.jpg';
import swiftScreenRecipes4X1600 from './img/swift/1600/screenRecipes4.jpg';
import swiftScreenRecipes5x1X1600 from './img/swift/1600/screenRecipes5-1.jpg';
import swiftScreenRecipes5x2X1600 from './img/swift/1600/screenRecipes5-2.jpg';
import swiftScreenRecipes6X1600 from './img/swift/1600/screenRecipes6.jpg';
import swiftScreenRecipes7X1600 from './img/swift/1600/screenRecipes7.jpg';
import swiftScreenRecipes8x1X1600 from './img/swift/1600/screenRecipes8-1.jpg';
import swiftScreenRecipes8x2X1600 from './img/swift/1600/screenRecipes8-2.jpg';
import swiftScreenRecipes9X1600 from './img/swift/1600/screenRecipes9.jpg';
import swiftScreenRecipes10X1600 from './img/swift/1600/screenRecipes10.jpg';
import swiftScreenBranding1X1600 from './img/swift/1600/screenBranding1.jpg';
import swiftScreenBranding2X1600 from './img/swift/1600/screenBranding2.jpg';
import swiftScreenBranding3X1600 from './img/swift/1600/screenBranding3.jpg';
import swiftScreenBranding4X1600 from './img/swift/1600/screenBranding4.jpg';
import swiftScreenBranding5X1600 from './img/swift/1600/screenBranding5.jpg';
import swiftScreenBranding6X1600 from './img/swift/1600/screenBranding6.jpg';
import swiftScreenBranding7X1600 from './img/swift/1600/screenBranding7.jpg';
import bannerImgMegacvetX1600 from './img/megacvet/1600/projectBannerImg.jpg';

// 1000px
import projectImgSwiftX1000 from './img/swift/1000/projectPageSwift1.jpg';
import bannerImgSwiftX1000 from './img/swift/1000/projectSwiftBannerImg.jpg';
import swiftScreenImg1X1000 from './img/swift/1000/screen1.jpg';
import swiftScreenImg2X1000 from './img/swift/1000/screen2.jpg';
import swiftScreenImg3x1X1000 from './img/swift/1000/screen3-1.jpg';
import swiftScreenImg3x2X1000 from './img/swift/1000/screen3-2.jpg';
import swiftScreenImg4X1000 from './img/swift/1000/screen4.jpg';
import swiftScreenImg5x1X1000 from './img/swift/1000/screen5-1.jpg';
import swiftScreenImg5x2X1000 from './img/swift/1000/screen5-2.jpg';
import swiftScreenRecipes1X1000 from './img/swift/1000/screenRecipes1.jpg';
import swiftScreenRecipes2X1000 from './img/swift/1000/screenRecipes2.jpg';
import swiftScreenRecipes3X1000 from './img/swift/1000/screenRecipes3.jpg';
import swiftScreenRecipes4X1000 from './img/swift/1000/screenRecipes4.jpg';
import swiftScreenRecipes5x1X1000 from './img/swift/1000/screenRecipes5-1.jpg';
import swiftScreenRecipes5x2X1000 from './img/swift/1000/screenRecipes5-2.jpg';
import swiftScreenRecipes6X1000 from './img/swift/1000/screenRecipes6.jpg';
import swiftScreenRecipes7X1000 from './img/swift/1000/screenRecipes7.jpg';
import swiftScreenRecipes8x1X1000 from './img/swift/1000/screenRecipes8-1.jpg';
import swiftScreenRecipes8x2X1000 from './img/swift/1000/screenRecipes8-2.jpg';
import swiftScreenRecipes9X1000 from './img/swift/1000/screenRecipes9.jpg';
import swiftScreenRecipes10X1000 from './img/swift/1000/screenRecipes10.jpg';
import swiftScreenBranding1X1000 from './img/swift/1000/screenBranding1.jpg';
import swiftScreenBranding2X1000 from './img/swift/1000/screenBranding2.jpg';
import swiftScreenBranding3X1000 from './img/swift/1000/screenBranding3.jpg';
import swiftScreenBranding4X1000 from './img/swift/1000/screenBranding4.jpg';
import swiftScreenBranding5X1000 from './img/swift/1000/screenBranding5.jpg';
import swiftScreenBranding6X1000 from './img/swift/1000/screenBranding6.jpg';
import swiftScreenBranding7X1000 from './img/swift/1000/screenBranding7.jpg';
import bannerImgMegacvetX1000 from './img/megacvet/1000/projectBannerImg.jpg';

// 640px
import projectImgSwiftX640 from './img/swift/640/projectPageSwift1.jpg';
import bannerImgSwiftX640 from './img/swift/640/projectSwiftBannerImg.jpg';
import swiftScreenImg1X640 from './img/swift/640/screen1.jpg';
import swiftScreenImg2X640 from './img/swift/640/screen2.jpg';
import swiftScreenImg3x1X640 from './img/swift/640/screen3-1.jpg';
import swiftScreenImg3x2X640 from './img/swift/640/screen3-2.jpg';
import swiftScreenImg4X640 from './img/swift/640/screen4.jpg';
import swiftScreenImg5x1X640 from './img/swift/640/screen5-1.jpg';
import swiftScreenImg5x2X640 from './img/swift/640/screen5-2.jpg';
import swiftScreenRecipes1X640 from './img/swift/640/screenRecipes1.jpg';
import swiftScreenRecipes2X640 from './img/swift/640/screenRecipes2.jpg';
import swiftScreenRecipes3X640 from './img/swift/640/screenRecipes3.jpg';
import swiftScreenRecipes4X640 from './img/swift/640/screenRecipes4.jpg';
import swiftScreenRecipes5x1X640 from './img/swift/640/screenRecipes5-1.jpg';
import swiftScreenRecipes5x2X640 from './img/swift/640/screenRecipes5-2.jpg';
import swiftScreenRecipes6X640 from './img/swift/640/screenRecipes6.jpg';
import swiftScreenRecipes7X640 from './img/swift/640/screenRecipes7.jpg';
import swiftScreenRecipes8x1X640 from './img/swift/640/screenRecipes8-1.jpg';
import swiftScreenRecipes8x2X640 from './img/swift/640/screenRecipes8-2.jpg';
import swiftScreenRecipes9X640 from './img/swift/640/screenRecipes9.jpg';
import swiftScreenRecipes10X640 from './img/swift/640/screenRecipes10.jpg';
import swiftScreenBranding1X640 from './img/swift/640/screenBranding1.jpg';
import swiftScreenBranding2X640 from './img/swift/640/screenBranding2.jpg';
import swiftScreenBranding3X640 from './img/swift/640/screenBranding3.jpg';
import swiftScreenBranding4X640 from './img/swift/640/screenBranding4.jpg';
import swiftScreenBranding5X640 from './img/swift/640/screenBranding5.jpg';
import swiftScreenBranding6X640 from './img/swift/640/screenBranding6.jpg';
import swiftScreenBranding7X640 from './img/swift/640/screenBranding7.jpg';
import bannerImgMegacvetX640 from './img/megacvet/640/projectBannerImg.jpg';

// 320px
import projectImgSwiftX320 from './img/swift/320/projectPageSwift1.jpg';
import bannerImgSwiftX320 from './img/swift/320/projectSwiftBannerImg.jpg';
import swiftScreenImg1X320 from './img/swift/320/screen1.jpg';
import swiftScreenImg2X320 from './img/swift/320/screen2.jpg';
import swiftScreenImg3x1X320 from './img/swift/320/screen3-1.jpg';
import swiftScreenImg3x2X320 from './img/swift/320/screen3-2.jpg';
import swiftScreenImg4X320 from './img/swift/320/screen4.jpg';
import swiftScreenImg5x1X320 from './img/swift/320/screen5-1.jpg';
import swiftScreenImg5x2X320 from './img/swift/320/screen5-2.jpg';
import swiftScreenRecipes1X320 from './img/swift/320/screenRecipes1.jpg';
import swiftScreenRecipes2X320 from './img/swift/320/screenRecipes2.jpg';
import swiftScreenRecipes3X320 from './img/swift/320/screenRecipes3.jpg';
import swiftScreenRecipes4X320 from './img/swift/320/screenRecipes4.jpg';
import swiftScreenRecipes5x1X320 from './img/swift/320/screenRecipes5-1.jpg';
import swiftScreenRecipes5x2X320 from './img/swift/320/screenRecipes5-2.jpg';
import swiftScreenRecipes6X320 from './img/swift/320/screenRecipes6.jpg';
import swiftScreenRecipes7X320 from './img/swift/320/screenRecipes7.jpg';
import swiftScreenRecipes8x1X320 from './img/swift/320/screenRecipes8-1.jpg';
import swiftScreenRecipes8x2X320 from './img/swift/320/screenRecipes8-2.jpg';
import swiftScreenRecipes9X320 from './img/swift/320/screenRecipes9.jpg';
import swiftScreenRecipes10X320 from './img/swift/320/screenRecipes10.jpg';
import swiftScreenBranding1X320 from './img/swift/320/screenBranding1.jpg';
import swiftScreenBranding2X320 from './img/swift/320/screenBranding2.jpg';
import swiftScreenBranding3X320 from './img/swift/320/screenBranding3.jpg';
import swiftScreenBranding4X320 from './img/swift/320/screenBranding4.jpg';
import swiftScreenBranding5X320 from './img/swift/320/screenBranding5.jpg';
import swiftScreenBranding6X320 from './img/swift/320/screenBranding6.jpg';
import swiftScreenBranding7X320 from './img/swift/320/screenBranding7.jpg';
import bannerImgMegacvetX320 from './img/megacvet/320/projectBannerImg.jpg';

let projectImgSwift;
let bannerImgSwift;
let swiftScreenImg1;
let swiftScreenImg2;
let swiftScreenImg3x1;
let swiftScreenImg3x2;
let swiftScreenImg4;
let swiftScreenImg5x1;
let swiftScreenImg5x2;
let swiftScreenRecipes1;
let swiftScreenRecipes2;
let swiftScreenRecipes3;
let swiftScreenRecipes4;
let swiftScreenRecipes5x1;
let swiftScreenRecipes5x2;
let swiftScreenRecipes6;
let swiftScreenRecipes7;
let swiftScreenRecipes8x1;
let swiftScreenRecipes8x2;
let swiftScreenRecipes9;
let swiftScreenRecipes10;
let swiftScreenBranding1;
let swiftScreenBranding2;
let swiftScreenBranding3;
let swiftScreenBranding4;
let swiftScreenBranding5;
let swiftScreenBranding6;
let swiftScreenBranding7;
let bannerImgMegacvet;

function updateImgs() {
  if (window.innerWidth >= 1200) {
    projectImgSwift = projectImgSwiftX1600;
    bannerImgSwift = bannerImgSwiftX1600;
    swiftScreenImg1 = swiftScreenImg1X1600;
    swiftScreenImg2 = swiftScreenImg2X1600;
    swiftScreenImg3x1 = swiftScreenImg3x1X1600;
    swiftScreenImg3x2 = swiftScreenImg3x2X1600;
    swiftScreenImg4 = swiftScreenImg4X1600;
    swiftScreenImg5x1 = swiftScreenImg5x1X1600;
    swiftScreenImg5x2 = swiftScreenImg5x2X1600;
    swiftScreenRecipes1 = swiftScreenRecipes1X1600;
    swiftScreenRecipes2 = swiftScreenRecipes2X1600;
    swiftScreenRecipes3 = swiftScreenRecipes3X1600;
    swiftScreenRecipes4 = swiftScreenRecipes4X1600;
    swiftScreenRecipes5x1 = swiftScreenRecipes5x1X1600;
    swiftScreenRecipes5x2 = swiftScreenRecipes5x2X1600;
    swiftScreenRecipes6 = swiftScreenRecipes6X1600;
    swiftScreenRecipes7 = swiftScreenRecipes7X1600;
    swiftScreenRecipes8x1 = swiftScreenRecipes8x1X1600;
    swiftScreenRecipes8x2 = swiftScreenRecipes8x2X1600;
    swiftScreenRecipes9 = swiftScreenRecipes9X1600;
    swiftScreenRecipes10 = swiftScreenRecipes10X1600;
    swiftScreenBranding1 = swiftScreenBranding1X1600;
    swiftScreenBranding2 = swiftScreenBranding2X1600;
    swiftScreenBranding3 = swiftScreenBranding3X1600;
    swiftScreenBranding4 = swiftScreenBranding4X1600;
    swiftScreenBranding5 = swiftScreenBranding5X1600;
    swiftScreenBranding6 = swiftScreenBranding6X1600;
    swiftScreenBranding7 = swiftScreenBranding7X1600;
    bannerImgMegacvet = bannerImgMegacvetX1600;
  } else if (window.innerWidth < 1200 && window.innerWidth >= 900) {
    projectImgSwift = projectImgSwiftX1000;
    bannerImgSwift = bannerImgSwiftX1000;
    swiftScreenImg1 = swiftScreenImg1X1000;
    swiftScreenImg2 = swiftScreenImg2X1000;
    swiftScreenImg3x1 = swiftScreenImg3x1X1000;
    swiftScreenImg3x2 = swiftScreenImg3x2X1000;
    swiftScreenImg4 = swiftScreenImg4X1000;
    swiftScreenImg5x1 = swiftScreenImg5x1X1000;
    swiftScreenImg5x2 = swiftScreenImg5x2X1000;
    swiftScreenRecipes1 = swiftScreenRecipes1X1000;
    swiftScreenRecipes2 = swiftScreenRecipes2X1000;
    swiftScreenRecipes3 = swiftScreenRecipes3X1000;
    swiftScreenRecipes4 = swiftScreenRecipes4X1000;
    swiftScreenRecipes5x1 = swiftScreenRecipes5x1X1000;
    swiftScreenRecipes5x2 = swiftScreenRecipes5x2X1000;
    swiftScreenRecipes6 = swiftScreenRecipes6X1000;
    swiftScreenRecipes7 = swiftScreenRecipes7X1000;
    swiftScreenRecipes8x1 = swiftScreenRecipes8x1X1000;
    swiftScreenRecipes8x2 = swiftScreenRecipes8x2X1000;
    swiftScreenRecipes9 = swiftScreenRecipes9X1000;
    swiftScreenRecipes10 = swiftScreenRecipes10X1000;
    swiftScreenBranding1 = swiftScreenBranding1X1000;
    swiftScreenBranding2 = swiftScreenBranding2X1000;
    swiftScreenBranding3 = swiftScreenBranding3X1000;
    swiftScreenBranding4 = swiftScreenBranding4X1000;
    swiftScreenBranding5 = swiftScreenBranding5X1000;
    swiftScreenBranding6 = swiftScreenBranding6X1000;
    swiftScreenBranding7 = swiftScreenBranding7X1000;
    bannerImgMegacvet = bannerImgMegacvetX1000;
  } else if (window.innerWidth < 900 && window.innerWidth >= 600) {
    projectImgSwift = projectImgSwiftX640;
    bannerImgSwift = bannerImgSwiftX640;
    swiftScreenImg1 = swiftScreenImg1X640;
    swiftScreenImg2 = swiftScreenImg2X640;
    swiftScreenImg3x1 = swiftScreenImg3x1X640;
    swiftScreenImg3x2 = swiftScreenImg3x2X640;
    swiftScreenImg4 = swiftScreenImg4X640;
    swiftScreenImg5x1 = swiftScreenImg5x1X640;
    swiftScreenImg5x2 = swiftScreenImg5x2X640;
    swiftScreenRecipes1 = swiftScreenRecipes1X640;
    swiftScreenRecipes2 = swiftScreenRecipes2X640;
    swiftScreenRecipes3 = swiftScreenRecipes3X640;
    swiftScreenRecipes4 = swiftScreenRecipes4X640;
    swiftScreenRecipes5x1 = swiftScreenRecipes5x1X640;
    swiftScreenRecipes5x2 = swiftScreenRecipes5x2X640;
    swiftScreenRecipes6 = swiftScreenRecipes6X640;
    swiftScreenRecipes7 = swiftScreenRecipes7X640;
    swiftScreenRecipes8x1 = swiftScreenRecipes8x1X640;
    swiftScreenRecipes8x2 = swiftScreenRecipes8x2X640;
    swiftScreenRecipes9 = swiftScreenRecipes9X640;
    swiftScreenRecipes10 = swiftScreenRecipes10X640;
    swiftScreenBranding1 = swiftScreenBranding1X640;
    swiftScreenBranding2 = swiftScreenBranding2X640;
    swiftScreenBranding3 = swiftScreenBranding3X640;
    swiftScreenBranding4 = swiftScreenBranding4X640;
    swiftScreenBranding5 = swiftScreenBranding5X640;
    swiftScreenBranding6 = swiftScreenBranding6X640;
    swiftScreenBranding7 = swiftScreenBranding7X640;
    bannerImgMegacvet = bannerImgMegacvetX640;
  } else if (window.innerWidth < 600) {
    projectImgSwift = projectImgSwiftX320;
    bannerImgSwift = bannerImgSwiftX320;
    swiftScreenImg1 = swiftScreenImg1X320;
    swiftScreenImg2 = swiftScreenImg2X320;
    swiftScreenImg3x1 = swiftScreenImg3x1X320;
    swiftScreenImg3x2 = swiftScreenImg3x2X320;
    swiftScreenImg4 = swiftScreenImg4X320;
    swiftScreenImg5x1 = swiftScreenImg5x1X320;
    swiftScreenImg5x2 = swiftScreenImg5x2X320;
    swiftScreenRecipes1 = swiftScreenRecipes1X320;
    swiftScreenRecipes2 = swiftScreenRecipes2X320;
    swiftScreenRecipes3 = swiftScreenRecipes3X320;
    swiftScreenRecipes4 = swiftScreenRecipes4X320;
    swiftScreenRecipes5x1 = swiftScreenRecipes5x1X320;
    swiftScreenRecipes5x2 = swiftScreenRecipes5x2X320;
    swiftScreenRecipes6 = swiftScreenRecipes6X320;
    swiftScreenRecipes7 = swiftScreenRecipes7X320;
    swiftScreenRecipes8x1 = swiftScreenRecipes8x1X320;
    swiftScreenRecipes8x2 = swiftScreenRecipes8x2X320;
    swiftScreenRecipes9 = swiftScreenRecipes9X320;
    swiftScreenRecipes10 = swiftScreenRecipes10X320;
    swiftScreenBranding1 = swiftScreenBranding1X320;
    swiftScreenBranding2 = swiftScreenBranding2X320;
    swiftScreenBranding3 = swiftScreenBranding3X320;
    swiftScreenBranding4 = swiftScreenBranding4X320;
    swiftScreenBranding5 = swiftScreenBranding5X320;
    swiftScreenBranding6 = swiftScreenBranding6X320;
    swiftScreenBranding7 = swiftScreenBranding7X320;
    bannerImgMegacvet = bannerImgMegacvetX320;
  }
}
updateImgs();

const initPageData = {
  banner: {
    name: 'Swift Global ',
    services: 'Разработка, UI/UX, Брендинг, Поддержка',
    image: bannerImgSwift,
    buttonText: 'Посетить сайт',
    buttonLink: 'https://swiftglobal.cy',
  },
  about: {
    text: 'Swift Global LTD — международная компания, которая занимается продажей и хранением драгоценных металлов на Кипре.',
    tasks: ['Разработали фирменный стиль', 'Сделали лендинг', 'Сделали сайт', 'Оформили социальные сети', 'Создали макеты печатной продукции'],
    year: '2021 — 2022',
  },
  firstImage: projectImgSwift,
  mainScreens: {
    isScreens: true,
    label: 'Главная страница',
    text: 'Сайт создан на основе брендбука и лендинга компании. Основные задачи сайта: долгосрочная работа с лояльными пользователями, а также продажа услуг новым клиентам.',
    images: [
      {
        img: swiftScreenImg1,
        type: 'largeImg',
      }, {
        img: swiftScreenImg2,
        type: 'mediumImg',
      }, {
        img: [swiftScreenImg3x1, swiftScreenImg3x2],
        type: 'leftTopRightBottomImgs',
      }, {
        img: swiftScreenImg4,
        type: 'mediumImg',
      }, {
        img: [swiftScreenImg5x1, swiftScreenImg5x2],
        type: 'mediumShortLongImgs',
      }],
  },
  textWithScreens: {
    isScreens: false,
    title: 'Основные функции',
    blocks: [
      {
        label: 'Расчёт экономии',
        p: 'С помощью калькулятора экономии потенциальный клиент может посчитать выгоду, которую принесёт ему сотрудничество с компанией. Это добавляет конкретики и создаёт ощущение прозрачности.',
        type: 'textBlock',
      }, {
        img: swiftScreenRecipes1,
        type: 'mediumImg',
      }, {
        img: swiftScreenRecipes2,
        type: 'largeImg',
      }, {
        img: swiftScreenRecipes3,
        type: 'mediumImg',
      }, {
        label: 'Услуги и выгоды',
        p: 'Мы сделали отдельные страницы под все услуги, которые предоставляет компания, а также их преимущества, что позволяет клиенту узнать всю информацию, которая может понадобится для принятия решения.',
        type: 'textBlockRight',
      }, {
        img: swiftScreenRecipes4,
        type: 'mediumImg',
      }, {
        img: [swiftScreenRecipes5x1, swiftScreenRecipes5x2],
        type: 'topShortLongImgs',
      }, {
        img: swiftScreenRecipes6,
        type: 'largeImg',
      }, {
        label: 'Покупка металлов онлайн',
        p: 'Наша команда спроектировала сценарий быстрой покупки драгоценных металлов онлайн, руководствуясь законами юзабилити и требованиями к высоко конверсионным формам.',
        type: 'textBlock',
      }, {
        img: swiftScreenRecipes7,
        type: 'mediumImg',
      }, {
        img: [swiftScreenRecipes8x1, swiftScreenRecipes8x2],
        type: 'topShortLongImgs',
      }, {
        img: swiftScreenRecipes9,
        type: 'mediumImg',
      }, {
        img: swiftScreenRecipes10,
        type: 'largeImg',
      }],
  },
  swiftBranding: {
    isScreens: true,
    label: 'Брендинг',
    text: 'В основе стиля лежит синий цвет. Он передаёт атмосферу надёжности и спокойствия. Также используются прямоугольные формы с острыми углами и круги, которые контрастируют друг с другом и привлекают внимание.',
    images: [
      {
        img: swiftScreenBranding1,
        type: 'mediumImg',
      }, {
        img: [swiftScreenBranding2, swiftScreenBranding3],
        type: 'topShortLongImgs',
      }, {
        img: swiftScreenBranding4,
        type: 'img75',
      }, {
        img: swiftScreenBranding5,
        type: 'smallImgBottom',
      }, {
        img: [swiftScreenBranding6, swiftScreenBranding7],
        type: 'smallMediumRow',
      }],
  },
  nextProject: {
    name: 'Мегацвет24 ',
    image: bannerImgMegacvet,
    link: '/megacvet',
  },
};

export default function SwiftPage() {
  const [pageData, setPageData] = React.useState(initPageData);
  const [currentSize, setCurrentSize] = React.useState(0);
  const { scroll } = useLocomotiveScroll();
  const { width } = useWindowDimensions();

  function updateCaseObject() {
    const newPageData = { ...pageData };

    newPageData.banner.image = bannerImgSwift;
    newPageData.firstImage = projectImgSwift;
    newPageData.mainScreens.images = [
      {
        img: swiftScreenImg1,
        type: 'largeImg',
      }, {
        img: swiftScreenImg2,
        type: 'mediumImg',
      }, {
        img: [swiftScreenImg3x1, swiftScreenImg3x2],
        type: 'leftTopRightBottomImgs',
      }, {
        img: swiftScreenImg4,
        type: 'mediumImg',
      }, {
        img: [swiftScreenImg5x1, swiftScreenImg5x2],
        type: 'mediumShortLongImgs',
      }];
    newPageData.textWithScreens.blocks = [
      {
        label: 'Расчёт экономии',
        p: 'С помощью калькулятора экономии потенциальный клиент может посчитать выгоду, которую принесёт ему сотрудничество с компанией. Это добавляет конкретики и создаёт ощущение прозрачности.',
        type: 'textBlock',
      }, {
        img: swiftScreenRecipes1,
        type: 'mediumImg',
      }, {
        img: swiftScreenRecipes2,
        type: 'largeImg',
      }, {
        img: swiftScreenRecipes3,
        type: 'mediumImg',
      }, {
        label: 'Услуги и выгоды',
        p: 'Мы сделали отдельные страницы под все услуги, которые предоставляет компания, а также их преимущества, что позволяет клиенту узнать всю информацию, которая может понадобится для принятия решения.',
        type: 'textBlockRight',
      }, {
        img: swiftScreenRecipes4,
        type: 'mediumImg',
      }, {
        img: [swiftScreenRecipes5x1, swiftScreenRecipes5x2],
        type: 'topShortLongImgs',
      }, {
        img: swiftScreenRecipes6,
        type: 'largeImg',
      }, {
        label: 'Покупка металлов онлайн',
        p: 'Наша команда спроектировала сценарий быстрой покупки драгоценных металлов онлайн, руководствуясь законами юзабилити и требованиями к высоко конверсионным формам.',
        type: 'textBlock',
      }, {
        img: swiftScreenRecipes7,
        type: 'mediumImg',
      }, {
        img: [swiftScreenRecipes8x1, swiftScreenRecipes8x2],
        type: 'topShortLongImgs',
      }, {
        img: swiftScreenRecipes9,
        type: 'mediumImg',
      }, {
        img: swiftScreenRecipes10,
        type: 'largeImg',
      }];
    newPageData.swiftBranding.images = [
      {
        img: swiftScreenBranding1,
        type: 'mediumImg',
      }, {
        img: [swiftScreenBranding2, swiftScreenBranding3],
        type: 'topShortLongImgs',
      }, {
        img: swiftScreenBranding4,
        type: 'img75',
      }, {
        img: swiftScreenBranding5,
        type: 'smallImgBottom',
      }, {
        img: [swiftScreenBranding6, swiftScreenBranding7],
        type: 'smallMediumRow',
      }];
    newPageData.nextProject.image = bannerImgMegacvet;

    setPageData(newPageData);
  }

  useEffect(() => {
    if (width >= 1200) {
      if (currentSize !== 1440) {
        updateImgs();
        updateCaseObject();
        setCurrentSize(1440);
      }
    } else if (width < 1200 && width >= 900) {
      if (currentSize !== 1000) {
        updateImgs();
        updateCaseObject();
        setCurrentSize(1000);
      }
    } else if (width < 900 && width >= 600) {
      if (currentSize !== 640) {
        updateImgs();
        updateCaseObject();
        setCurrentSize(640);
      }
    } else if (width < 600) {
      if (currentSize !== 320) {
        updateImgs();
        updateCaseObject();
        setCurrentSize(320);
      }
    }
  }, [width]);

  useEffect(() => {
    if (scroll) {
      const header = document.getElementsByClassName('header')[0];
      let prevScrollpos = 0;
      scroll.on('scroll', (e) => {
        const currentScrollPos = e.scroll.y;
        if (currentScrollPos > 0) {
          header.classList.add('filled');
        }
        if (currentScrollPos - prevScrollpos > 10) {
          header.classList.add('hidden');
          prevScrollpos = currentScrollPos;
        }
        if (prevScrollpos - currentScrollPos > 10) {
          header.classList.remove('hidden');
          prevScrollpos = currentScrollPos;
        }
        if (currentScrollPos < 50) {
          header.classList.remove('filled');
        }
        if (currentScrollPos < 10) {
          header.classList.remove('hidden');
        }
      });
    }
  }, [scroll]);

  return (
    <div data-scroll-section="" className="projectPage">
      <Header color="black" />
      <ProjectBanner
        name={pageData.banner.name}
        services={pageData.banner.services}
        image={pageData.banner.image}
        buttonText={pageData.banner.buttonText}
        link={pageData.banner.buttonLink}
      />
      <ProjectAbout
        text={pageData.about.text}
        tasks={pageData.about.tasks}
        year={pageData.about.year}
      />
      <div className="parallaxBlock">
        <img data-scroll="" data-scroll-speed="-1.5" alt="" src={pageData.firstImage} />
      </div>

      <MainScreens
        isScreens={pageData.mainScreens.isScreens}
        label={pageData.mainScreens.label}
        text={pageData.mainScreens.text}
        blocks={pageData.mainScreens.images}
      />
      <MainScreens
        isScreens={pageData.textWithScreens.isScreens}
        title={pageData.textWithScreens.title}
        blocks={pageData.textWithScreens.blocks}
      />
      <MainScreens
        isScreens={pageData.swiftBranding.isScreens}
        label={pageData.swiftBranding.label}
        text={pageData.swiftBranding.text}
        blocks={pageData.swiftBranding.images}
      />

      <NextProject
        link={pageData.nextProject.link}
        image={pageData.nextProject.image}
        name={pageData.nextProject.name}
      />

      <LetsWorkTogether />
      <Footer />
    </div>
  );
}
