export default {
  app: 'Приложение',
  site: 'Многостраничный сайт',
  landing: 'Лендинг',
  support: 'Поддержка',
  development: 'Разработка',
  design: 'Дизайн',
  additional: 'Дополнительные услуги',
  idk: 'Я не знаю',
};
