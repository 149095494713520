import React, { useEffect } from 'react';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import Header from '../../shared_components/Header/Header';
import Banner from './moleculas/Banner/Banner';
import AboutBlock from './moleculas/AboutBlock/AboutBlock';
import ProjectsList from '../../shared_components/ProjectsList/ProjectsList';
import Clients from './moleculas/Clients/Clients';
import RunningPageIcons from '../../shared_components/RunningPageIcons/RunningPageIcons';
import LetsWorkTogether from '../../shared_components/LetsWorkTogether/LetsWorkTogether';
import Footer from '../../shared_components/Footer/Footer';
import '../../locomotive-scroll.css';

export default function MainPage() {
  const { scroll } = useLocomotiveScroll();
  useEffect(() => {
    if (scroll) {
      const header = document.getElementsByClassName('header')[0];
      let prevScrollpos = 0;
      scroll.on('scroll', (e) => {
        const currentScrollPos = e.scroll.y;
        if (currentScrollPos > 0) {
          header.classList.add('filled');
        }
        if (currentScrollPos - prevScrollpos > 10) {
          header.classList.add('hidden');
          prevScrollpos = currentScrollPos;
        }
        if (prevScrollpos - currentScrollPos > 10) {
          header.classList.remove('hidden');
          prevScrollpos = currentScrollPos;
        }
        if (currentScrollPos < 50) {
          header.classList.remove('filled');
        }
        if (currentScrollPos < 10) {
          header.classList.remove('hidden');
        }
      });
    }
  }, [scroll]);
  return (
    <div data-scroll-section="" className="mainPage">
      <Header color="black" />
      <Banner />
      <AboutBlock />
      <ProjectsList isShorted />
      <Clients />
      <RunningPageIcons />
      <LetsWorkTogether />
      <Footer />
    </div>
  );
}
