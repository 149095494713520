import React from 'react';
import './ProjectCard.css';
import arrowRight from './img/arrowRight.svg';

export default function ProjectCard(props) {
  return (
    <div data-scroll-offset={props.type === 'even' ? '40%' : '20%'} data-scroll="" data-scroll-class="fadeUp-active" className={props.type === 'even' ? 'projectCard toFadeUpCard' : 'projectCard toFadeUpCard projectCardOdd'}>
      <div className="thumbnail">
        <img alt="" className="projectImage" src={props.img} />
      </div>
      <div className="row">
        <div className="text">
          <p>{props.title}</p>
          <label>{props.description}</label>
        </div>
        <img alt="" className="arrowRight" src={arrowRight} />
      </div>
    </div>
  );
}
