import React, { useEffect } from 'react';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ProjectBanner from './atoms/ProjectBanner/ProjectBanner';
import ProjectAbout from './atoms/ProjectAbout/ProjectAbout';
import MainScreens from './atoms/MainScreens/MainScreens';
import Footer from '../../shared_components/Footer/Footer';
import LetsWorkTogether from '../../shared_components/LetsWorkTogether/LetsWorkTogether';
import NextProject from './atoms/NextProject/NextProject';
import Header from '../../shared_components/Header/Header';
import './ProjectPage.css';

import bannerImgDspX1600 from './img/dsp/1600/bannerPageDsp1.jpg';
import projectImgDspX1600 from './img/dsp/1600/projectPageDsp1.jpg';
import dspScreenImg1X1600 from './img/dsp/1600/screen1.jpg';
import dspScreenImg2X1600 from './img/dsp/1600/screen2.jpg';
import dspScreenImg3X1600 from './img/dsp/1600/screen3.jpg';
import dspScreenImg4X1600 from './img/dsp/1600/screen4.jpg';
import dspBranding1X1600 from './img/dsp/1600/screenBranding1.jpg';
import dspBranding2X1600 from './img/dsp/1600/screenBranding2.jpg';
import dspBranding3X1600 from './img/dsp/1600/screenBranding3.jpg';
import dspBranding4X1600 from './img/dsp/1600/screenBranding4.jpg';
import dspBranding5X1600 from './img/dsp/1600/screenBranding5.jpg';
import bannerImgDspX1000 from './img/dsp/1000/bannerPageDsp1.jpg';
import projectImgDspX1000 from './img/dsp/1000/projectPageDsp1.jpg';
import dspScreenImg1X1000 from './img/dsp/1000/screen1.jpg';
import dspScreenImg2X1000 from './img/dsp/1000/screen2.jpg';
import dspScreenImg3X1000 from './img/dsp/1000/screen3.jpg';
import dspScreenImg4X1000 from './img/dsp/1000/screen4.jpg';
import dspBranding1X1000 from './img/dsp/1000/screenBranding1.jpg';
import dspBranding2X1000 from './img/dsp/1000/screenBranding2.jpg';
import dspBranding3X1000 from './img/dsp/1000/screenBranding3.jpg';
import dspBranding4X1000 from './img/dsp/1000/screenBranding4.jpg';
import dspBranding5X1000 from './img/dsp/1000/screenBranding5.jpg';
import bannerImgDspX640 from './img/dsp/640/bannerPageDsp1.jpg';
import projectImgDspX640 from './img/dsp/640/projectPageDsp1.jpg';
import dspScreenImg1X640 from './img/dsp/640/screen1.jpg';
import dspScreenImg2X640 from './img/dsp/640/screen2.jpg';
import dspScreenImg3X640 from './img/dsp/640/screen3.jpg';
import dspScreenImg4X640 from './img/dsp/640/screen4.jpg';
import dspBranding1X640 from './img/dsp/640/screenBranding1.jpg';
import dspBranding2X640 from './img/dsp/640/screenBranding2.jpg';
import dspBranding3X640 from './img/dsp/640/screenBranding3.jpg';
import dspBranding4X640 from './img/dsp/640/screenBranding4.jpg';
import dspBranding5X640 from './img/dsp/640/screenBranding5.jpg';
import bannerImgDspX320 from './img/dsp/320/bannerPageDsp1.jpg';
import projectImgDspX320 from './img/dsp/320/projectPageDsp1.jpg';
import dspScreenImg1X320 from './img/dsp/320/screen1.jpg';
import dspScreenImg2X320 from './img/dsp/320/screen2.jpg';
import dspScreenImg3X320 from './img/dsp/320/screen3.jpg';
import dspScreenImg4X320 from './img/dsp/320/screen4.jpg';
import dspBranding1X320 from './img/dsp/320/screenBranding1.jpg';
import dspBranding2X320 from './img/dsp/320/screenBranding2.jpg';
import dspBranding3X320 from './img/dsp/320/screenBranding3.jpg';
import dspBranding4X320 from './img/dsp/320/screenBranding4.jpg';
import dspBranding5X320 from './img/dsp/320/screenBranding5.jpg';

import bannerImgCanadianX1600 from './img/canadian/1600/bannerPageCanadian1.jpg';
import bannerImgCanadianX1000 from './img/canadian/1000/bannerPageCanadian1.jpg';
import bannerImgCanadianX640 from './img/canadian/640/bannerPageCanadian1.jpg';
import bannerImgCanadianX320 from './img/canadian/320/bannerPageCanadian1.jpg';

let bannerImgDsp;
let projectImgDsp;
let dspScreenImg1;
let dspScreenImg2;
let dspScreenImg3;
let dspScreenImg4;
let dspBranding1;
let dspBranding2;
let dspBranding3;
let dspBranding4;
let dspBranding5;
let bannerImgCanadian;

function updateImgs() {
  if (window.innerWidth >= 1200) {
    bannerImgDsp = bannerImgDspX1600;
    projectImgDsp = projectImgDspX1600;
    dspScreenImg1 = dspScreenImg1X1600;
    dspScreenImg2 = dspScreenImg2X1600;
    dspScreenImg3 = dspScreenImg3X1600;
    dspScreenImg4 = dspScreenImg4X1600;
    dspBranding1 = dspBranding1X1600;
    dspBranding2 = dspBranding2X1600;
    dspBranding3 = dspBranding3X1600;
    dspBranding4 = dspBranding4X1600;
    dspBranding5 = dspBranding5X1600;
    bannerImgCanadian = bannerImgCanadianX1600;
  } else if (window.innerWidth < 1200 && window.innerWidth >= 900) {
    bannerImgDsp = bannerImgDspX1000;
    projectImgDsp = projectImgDspX1000;
    dspScreenImg1 = dspScreenImg1X1000;
    dspScreenImg2 = dspScreenImg2X1000;
    dspScreenImg3 = dspScreenImg3X1000;
    dspScreenImg4 = dspScreenImg4X1000;
    dspBranding1 = dspBranding1X1000;
    dspBranding2 = dspBranding2X1000;
    dspBranding3 = dspBranding3X1000;
    dspBranding4 = dspBranding4X1000;
    dspBranding5 = dspBranding5X1000;
    bannerImgCanadian = bannerImgCanadianX1000;
  } else if (window.innerWidth < 900 && window.innerWidth >= 600) {
    bannerImgDsp = bannerImgDspX640;
    projectImgDsp = projectImgDspX640;
    dspScreenImg1 = dspScreenImg1X640;
    dspScreenImg2 = dspScreenImg2X640;
    dspScreenImg3 = dspScreenImg3X640;
    dspScreenImg4 = dspScreenImg4X640;
    dspBranding1 = dspBranding1X640;
    dspBranding2 = dspBranding2X640;
    dspBranding3 = dspBranding3X640;
    dspBranding4 = dspBranding4X640;
    dspBranding5 = dspBranding5X640;
    bannerImgCanadian = bannerImgCanadianX640;
  } else if (window.innerWidth < 600) {
    bannerImgDsp = bannerImgDspX320;
    projectImgDsp = projectImgDspX320;
    dspScreenImg1 = dspScreenImg1X320;
    dspScreenImg2 = dspScreenImg2X320;
    dspScreenImg3 = dspScreenImg3X320;
    dspScreenImg4 = dspScreenImg4X320;
    dspBranding1 = dspBranding1X320;
    dspBranding2 = dspBranding2X320;
    dspBranding3 = dspBranding3X320;
    dspBranding4 = dspBranding4X320;
    dspBranding5 = dspBranding5X320;
    bannerImgCanadian = bannerImgCanadianX320;
  }
}
updateImgs();

const initPageData = {
  banner: {
    name: 'DSP — projects ',
    services: 'Разработка',
    image: bannerImgDsp,
    buttonText: 'Проект закрыт',
  },
  about: {
    text: 'DSP.studio – студия дизайна, разработки, производства мебели и предметов интерьера из массива дерева и современных экологичных материалов.',
    tasks: ['Создали сайт-портфолио на WordPress'],
    year: '2021, сентябрь',
  },
  firstImage: projectImgDsp,
  textWithScreens: {
    isScreens: false,
    title: 'Основные функции',
    blocks: [{
      img: dspScreenImg1,
      type: 'largeImg',
    }, {
      img: dspScreenImg2,
      type: 'mediumImg',
    }, {
      img: dspScreenImg3,
      type: 'mediumImg',
    }, {
      img: dspScreenImg4,
      type: 'longLowImg',
    }],
  },
  branding: {
    isScreens: false,
    title: 'Брэндинг',
    blocks: [{
      img: dspBranding1,
      type: 'mediumImg',
    }, {
      img: dspBranding2,
      type: 'mediumImg',
    }, {
      img: dspBranding3,
      type: 'mediumImg',
    }, {
      img: [dspBranding4, dspBranding5],
      type: 'leftBottomRightTopImgs',
    }],
  },
  nextProject: {
    name: 'Canadian international ',
    image: bannerImgCanadian,
    link: '/canadian',
  },
};

export default function DspPage() {
  const [pageData, setPageData] = React.useState(initPageData);
  const [currentSize, setCurrentSize] = React.useState(0);
  const { scroll } = useLocomotiveScroll();
  const { width } = useWindowDimensions();

  function updateCaseObject() {
    const newPageData = { ...pageData };

    newPageData.banner.image = bannerImgDsp;
    newPageData.firstImage = projectImgDsp;
    newPageData.textWithScreens.blocks = [
      {
        img: dspScreenImg1,
        type: 'largeImg',
      }, {
        img: dspScreenImg2,
        type: 'mediumImg',
      }, {
        img: dspScreenImg3,
        type: 'mediumImg',
      }, {
        img: dspScreenImg4,
        type: 'longLowImg',
      }];
    newPageData.branding.blocks = [{
      img: dspBranding1,
      type: 'mediumImg',
    }, {
      img: dspBranding2,
      type: 'mediumImg',
    }, {
      img: dspBranding3,
      type: 'mediumImg',
    }, {
      img: [dspBranding4, dspBranding5],
      type: 'leftBottomRightTopImgs',
    }];
    newPageData.nextProject.image = bannerImgCanadian;

    setPageData(newPageData);
  }

  useEffect(() => {
    if (width >= 1200) {
      if (currentSize !== 1440) {
        updateImgs();
        updateCaseObject();
        setCurrentSize(1440);
      }
    } else if (width < 1200 && width >= 900) {
      if (currentSize !== 1000) {
        updateImgs();
        updateCaseObject();
        setCurrentSize(1000);
      }
    } else if (width < 900 && width >= 600) {
      if (currentSize !== 640) {
        updateImgs();
        updateCaseObject();
        setCurrentSize(640);
      }
    } else if (width < 600) {
      if (currentSize !== 320) {
        updateImgs();
        updateCaseObject();
        setCurrentSize(320);
      }
    }
  }, [width]);

  useEffect(() => {
    if (scroll) {
      const header = document.getElementsByClassName('header')[0];
      let prevScrollpos = 0;
      scroll.on('scroll', (e) => {
        const currentScrollPos = e.scroll.y;
        if (currentScrollPos > 0) {
          header.classList.add('filled');
        }
        if (currentScrollPos - prevScrollpos > 10) {
          header.classList.add('hidden');
          prevScrollpos = currentScrollPos;
        }
        if (prevScrollpos - currentScrollPos > 10) {
          header.classList.remove('hidden');
          prevScrollpos = currentScrollPos;
        }
        if (currentScrollPos < 50) {
          header.classList.remove('filled');
        }
        if (currentScrollPos < 10) {
          header.classList.remove('hidden');
        }
      });
    }
  }, [scroll]);

  return (
    <div data-scroll-section="" className="projectPage">
      <Header color="black" />
      <ProjectBanner
        name={pageData.banner.name}
        services={pageData.banner.services}
        image={pageData.banner.image}
        buttonText={pageData.banner.buttonText}
        link={pageData.banner.buttonLink}
      />
      <ProjectAbout
        text={pageData.about.text}
        tasks={pageData.about.tasks}
        year={pageData.about.year}
      />

      <div className="parallaxBlock">
        <img data-scroll="" data-scroll-speed="-1.5" alt="" src={pageData.firstImage} />
      </div>

      <MainScreens
        isScreens={pageData.textWithScreens.isScreens}
        title={pageData.textWithScreens.title}
        blocks={pageData.textWithScreens.blocks}
      />
      <MainScreens
        isScreens={pageData.branding.isScreens}
        title={pageData.branding.title}
        blocks={pageData.branding.blocks}
      />

      <NextProject
        link={pageData.nextProject.link}
        image={pageData.nextProject.image}
        name={pageData.nextProject.name}
      />

      <LetsWorkTogether />
      <Footer />
    </div>
  );
}
