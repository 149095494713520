import React from 'react';
import { Link } from 'react-router-dom';
import arrowDown from './img/arrowDown.svg';
import logo from './img/logo.svg';
import './Footer.css';

export default function Footer() {
  return (
    <div className="footer">
      <div data-scroll-speed="-4" data-scroll="" className="container">
        <div className="row content">
          <img alt="" src={logo} />
          <div data-scroll="" data-scroll-offset="20%" data-scroll-class="fadeUp-active" className="footerLinks toFadeUp">
            <Link className="animatedLink" to="/">
              <span>Главная</span>
              <div className="bottomLine white" />
            </Link>
            <Link className="animatedLink" to="/about">
              <span>О нас</span>
              <div className="bottomLine white" />
            </Link>
            <Link className="animatedLink" to="/projects">
              <span>Проекты</span>
              <div className="bottomLine white" />
            </Link>
            <Link className="animatedLink" to="/contacts">
              <span>Контакты</span>
              <div className="bottomLine white" />
            </Link>
          </div>
          <a href="/presentation.pdf" className="downloadFile" download>
            <div className="arrowCircle">
              <img alt="" src={arrowDown} />
            </div>
            <div className="text">
              <p>Скачать презентацию</p>
              <label>PDF, 8.7 mb</label>
            </div>
          </a>
          <div className="footerLinksMob">
            <div className="row">
              <div className="footerLinks">
                <Link className="animatedLink" to="/">
                  <span>Главная</span>
                  <div className="bottomLine white" />
                </Link>
                <Link className="animatedLink" to="/about">
                  <span>О нас</span>
                  <div className="bottomLine white" />
                </Link>
                <Link className="animatedLink" to="/projects">
                  <span>Проекты</span>
                  <div className="bottomLine white" />
                </Link>
                <Link className="animatedLink" to="/contacts">
                  <span>Контакты</span>
                  <div className="bottomLine white" />
                </Link>
              </div>
              <div className="social">
                <Link className="animatedLink" to="/contacts">
                  <span>Dribbble</span>
                  <div className="bottomLine white" />
                </Link>
                <Link className="animatedLink" to="/contacts">
                  <span>Behance</span>
                  <div className="bottomLine white" />
                </Link>
              </div>
            </div>
            <a href="/presentation.pdf" className="downloadFile" download>
              <div className="arrowCircle">
                <img alt="" src={arrowDown} />
              </div>
              <div className="text">
                <p>Скачать презентацию</p>
                <label>PDF, 8.7 mb</label>
              </div>
            </a>
          </div>
        </div>
        <div className="greyLine" />
        <div className="row">
          <p>©Polydev, 2019-2023</p>
          <a href="https://dribbble.com/Polydev" className="animatedLink" target="_blank" rel="noreferrer">
            <span>Dribbble</span>
            <div className="bottomLine white" />
          </a>
          <a href="https://www.behance.net/polidev_io" className="animatedLink" target="_blank" rel="noreferrer">
            <span>Behance</span>
            <div className="bottomLine white" />
          </a>
        </div>
      </div>
    </div>
  );
}
