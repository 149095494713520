import React from 'react';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import './SmallDropdownList.css';
import anime from 'animejs';

export default function SmallDropdownList(props) {
  const { scroll } = useLocomotiveScroll();
  function clickHandler(e) {
    if (e.currentTarget.classList.contains('opened')) {
      e.currentTarget.classList.remove('opened');
    } else {
      e.currentTarget.classList.add('opened');
    }
  }
  function showItems() {
    const items = document.getElementsByClassName('smallDropdownItem');
    const animation = anime.timeline({
      easing: 'linear',
      duration: 100,
    });
    animation
      .add({
        targets: items[0],
        translateY: 0,
        opacity: 1,
      })
      .add({
        targets: items[1],
        translateY: 0,
        opacity: 1,
      })
      .add({
        targets: items[2],
        translateY: 0,
        opacity: 1,
      })
      .add({
        targets: items[3],
        translateY: 0,
        opacity: 1,
      });
  }
  if (scroll) {
    scroll.on('call', (args) => {
      if (args === 'smallDropList') {
        showItems();
      }
    });
  }
  return (
    <div data-scroll-offset="20%" data-scroll="" data-scroll-call="smallDropList" className="smallDropdownList">
      {props.list.map((item, id) => (
        <div role="button" tabIndex={0} onClick={(e) => clickHandler(e)} key={id} className="smallDropdownItem toFadeUp">
          <div className="row">
            <span>{item.name}</span>
            <div className="plus">
              <div className="vertPart" />
              <div className="horizPart" />
            </div>

          </div>
          <p>{item.text}</p>
        </div>
      ))}
    </div>
  );
}
