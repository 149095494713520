// eslint-disable-next-line import/prefer-default-export
import vitaKit from './img/vitaKit.svg';
import swiftGlobal from './img/swiftGlobal.svg';
import megacolor from './img/megacolor.svg';
import rosSeti from './img/rosSeti.svg';
// import IGS from './img/IGS.svg';
import testu from './img/testu.svg';
import minDor from './img/minDor.svg';

export const smallDropdownList = [
  {
    name: 'Соблюдаем договорённости',
    text: 'Мы делаем то, что попросили. Например, если клиент просит сделать аккуратный сайт с функционалом, работающим как швейцарские часы, мы не сделаем для него мега-креативный сайт с мешающей анимацией.',
  },
  {
    name: 'Баланс между лучшим и нужным',
    text: 'Мы отбираем лучшие решения для продукта, которые доступны для бизнеса клиента и решают поставленные задачи. Мы не тратим ресурсы на ненужный функционал.',
  },
  {
    name: 'Эстетичный визуал',
    text: 'То, как продукт выглядит, может увеличить конверсию в разы, получить постоянных клиентов. Создавая визуальные решения, мы учитываем тренды, лучшие примеры, тематику проекта.',
  },
  {
    name: 'Всегда на связи',
    text: 'Поможем найти выход из непредвиденных ситуаций и будем на связи во время работы над проектом. После приёмки работ мы не только спросим, как дела, но и предложим поддержку с дополнительными услугами, если необходимо. ',
  },
];

export const caseImgs = [
  vitaKit,
  swiftGlobal,
  megacolor,
  rosSeti,
  testu,
  minDor,
];

// const aboutUsList = [
//   {
//     title: 'Приложения',
//     text: 'Мобильные приложения под платформы iOS и Android на нативных языках.',
//     data: [
//       {
//         title: 'Брифинг',
//         time: '2 дня',
//       },
//       {
//         title: 'UX/Аналитика',
//         time: 'от 5 дней',
//       },
//       {
//         title: 'Дизайн-концепция',
//         time: 'от 5 дней',
//       },
//       {
//         title: 'Дизайн всех экранов',
//         time: 'от 10 дней',
//       },
//       {
//         title: 'Разработка',
//         time: 'от 15 дней',
//       },
//     ],
//   },
//   {
//     title: 'Сайты',
//     text: 'Корпоративные сайты, интернет-магазины, СМИ, портфолио.',
//     data: [
//       {
//         title: 'Брифинг',
//         time: '2 дня',
//       },
//       {
//         title: 'UX/Аналитика',
//         time: 'от 5 дней',
//       },
//       {
//         title: 'Дизайн-концепция',
//         time: 'от 5 дней',
//       },
//       {
//         title: 'Дизайн внутренних страниц',
//         time: 'от 10 дней',
//       },
//       {
//         title: 'Адаптивы',
//         time: 'от 10 дней',
//       },
//       {
//         title: 'Разработка',
//         time: 'от 15 дней',
//       },
//     ],
//   },
//   {
//     title: 'Поддержка',
//     text: 'После реализации проекта мы не оставим вас в одиночестве.',
//     data: [
//       {
//         title: 'Обновление контента',
//         time: '',
//       },
//       {
//         title: 'Оперативное исправление проблем',
//         time: '',
//       },
//       {
//         title: 'Пользовательские интервью',
//         time: '',
//       },
//       {
//         title: 'Консультации',
//         time: '',
//       },
//     ],
//   },
//   {
//     title: 'Дополнительно',
//     text: 'Если вы заказали у нас основные услуги,
//     мы поможем вам быстро и недорого сделать сопровождающие задачи.',
//     data: [
//       {
//         title: 'Брендинг',
//         time: 'от 5 дней',
//       },
//       {
//         title: 'Создание презентаций',
//         time: 'от 3 дней',
//       },
//       {
//         title: 'Оформление соц.сетей',
//         time: 'от 5 дней',
//       },
//       {
//         title: 'Создание контента (фото, иллюстрации, копирайт)',
//         time: 'от 3 дней',
//       },
//     ],
//   },
// ];
