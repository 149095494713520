import React from 'react';
import './AnimatedInput.css';

export default function AnimatedInput({
  type,
  field,
  setField,
  label = '',
  htmlType = 'text',
  required = false,
}) {
  return (
    <div className="animatedInput">
      <input
        id={type}
        type={htmlType}
        value={field}
        placeholder=" "
        onChange={(e) => setField(type, e.target.value)}
        required={required}
      />
      <label htmlFor={type}>{label}</label>
    </div>
  );
}
