import React from 'react';
import { Link } from 'react-router-dom';
import './AboutBlock.css';
import ServicesList from '../../atoms/ServicesList/ServicesList';

export default function AboutBlock() {
  return (
    <div className="aboutBlock">
      <div data-scroll-offset="20%" data-scroll="" data-scroll-class="fadeUp-active" className="container toFadeUp">
        <label>Услуги</label>
        <h3>
          В каждом проекте команда Polydev ориентируется
          <span> </span>
          <br className="pc-br br-600" />
          на задачи  бизнеса.
          Мы анализируем целевую аудиторию, учитываем требования рынка,
          создаём дизайн на основе трендов
          <span> </span>
          <br className="br-600" />
          и поведения пользователей, поддерживаем продукты после релиза.
        </h3>
        <div className="row textBlock">
          <Link to="/about">
            <button type="button" data-strength-text="50" data-strength="100" className="animatedCircleButton magnetic">
              <p className="btn-text">О нас</p>
              <div className="btn-fill buttonBack" />
            </button>
          </Link>
          <div className="text">
            <div className="row">
              <div className="bigNumber">
                <h2>
                  30
                  <span>+</span>
                </h2>
                <p>Довольных клиентов</p>
              </div>
              <div className="bigNumber">
                <h2>
                  50
                  <span>+</span>
                </h2>
                <p>Реализованных проектов</p>
              </div>
            </div>
            <p>
              Ответственно подходим к любому проекту, применяем методики дизайн-мышления,
              строим гипотезы, тестируем, работаем
              <span> </span>
              <br className="pc-br br-1024" />
              с аудиторией, чтобы создавать удобные и
              дружелюбные проекты для пользователей.
            </p>
          </div>
        </div>
        <ServicesList />
      </div>
    </div>
  );
}
