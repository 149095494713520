import React, { useEffect } from 'react';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import Header from '../../shared_components/Header/Header';
import Footer from '../../shared_components/Footer/Footer';
import ContactsContent from './moleculas/ContactsContent/ContactsContent';
import './ContactsPage.css';

export default function ContactsPage() {
  const { scroll } = useLocomotiveScroll();
  useEffect(() => {
    if (scroll) {
      const header = document.getElementsByClassName('header')[0];
      let prevScrollpos = 0;
      scroll.on('scroll', (e) => {
        const currentScrollPos = e.scroll.y;
        if (currentScrollPos > 0) {
          header.classList.add('filled');
        }
        if (currentScrollPos - prevScrollpos > 10) {
          header.classList.add('hidden');
          prevScrollpos = currentScrollPos;
        }
        if (prevScrollpos - currentScrollPos > 10) {
          header.classList.remove('hidden');
          prevScrollpos = currentScrollPos;
        }
        if (currentScrollPos < 50) {
          header.classList.remove('filled');
        }
        if (currentScrollPos < 10) {
          header.classList.remove('hidden');
        }
      });
    }
  }, [scroll]);
  return (
    <div data-scroll-section="" className="contactsPage">
      <Header color="black" />
      <ContactsContent />
      <Footer />
    </div>
  );
}
