import client1 from './img/client1Img.svg';
import client2 from './img/client2Img.svg';
import client4 from './img/client4Img.svg';
import client6 from './img/client6Img.svg';
import client7 from './img/client7Img.svg';
import client8 from './img/client8Img.svg';

// eslint-disable-next-line import/prefer-default-export
export const clients = [{
  img: client1,
  title: 'Vitakit',
  description: 'Сервис заказа лекарств онлайн',
}, {
  img: client2,
  title: 'Swift Global',
  description: 'Международная сеть хранилищ драгоценных металлов',
}, {
  img: client7,
  title: 'Мегацвет24',
  description: 'Онлайн гипермаркет цветов',
}, {
  img: client4,
  title: 'Россети',
  description: 'Крупнейшая в России \nи мире энергетическая компания',
}, {
  img: client8,
  title: 'TestU.online',
  description: 'Платформа автоматизации школьной психодиагностики',
}, {
  img: client6,
  title: 'Минтранс Татарстана',
  description: 'Министерство транспорта республики Татарстан',
}];
