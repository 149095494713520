import React, { useRef } from 'react';
import './ContactsForm.css';
import sendMail from '../../../../services/sendMail';
import AnimatedInput from '../AnimatedInput/AnimatedInput';
import WorkTypeButton from '../WorkTypeButton/WorkTypeButton';
import workTypesList from './workTypesList';
import checkedField from './img/checkedField.svg';
import cross from './img/cross.svg';

const initFormState = {
  name: '',
  company: '',
  phone: '',
  email: '',
  budget: '',
  dontKnow: false,
};

const initWorkTypesState = {
  app: false,
  site: false,
  landing: false,
  support: false,
  development: false,
  design: false,
  additional: false,
  idk: false,
};

export default function ContactsForm() {
  const [form, setFormField] = React.useState(initFormState);
  const fileRef = useRef(null);
  const [file, setFile] = React.useState(null);
  const [task, setTask] = React.useState('');
  const [workTypes, setWorkTypes] = React.useState(initWorkTypesState);
  const [isSendSuccess, setIsSendSuccess] = React.useState(false);
  const [isSendError, setIsSendError] = React.useState(false);
  const [isSending, setIsSending] = React.useState(false);

  function changeField(field, value) {
    const newFields = {
      ...form,
      [field]: value,
    };
    setFormField(newFields);
  }

  function changeWorkType(work, value) {
    const newFields = {
      ...workTypes,
      [work]: value,
    };
    setWorkTypes(newFields);
  }

  const setFileHandler = () => {
    if (fileRef) {
      setFile(fileRef.current?.files?.[0]);
    }
  };

  const changeTaskHandler = (e) => {
    setTask(e.target.value);
  };

  const removeFileHandler = () => {
    fileRef.current.value = '';
    setFile(null);
  };

  const sendForm = async (e) => {
    e.preventDefault();

    setIsSending(true);
    setIsSendError(false);
    setIsSendSuccess(false);

    const isCorrectEmail = /\S+@\S+\.\S+/im.test(form.email.trim());

    if (form.name.trim() && isCorrectEmail) {
      const formData = new FormData();
      const chosenServices = Object.entries(workTypes)
        // eslint-disable-next-line no-unused-vars
        .filter(([_, value]) => value)
        .map(([key]) => workTypesList[key]);
      const services = chosenServices.length ? chosenServices.join(', ') : 'Нет выбранных услуг';
      const budget = form.budget ? form.budget : form.dontKnow ? 'Не известен' : 'Не указан';

      Object.entries(form).forEach(([key, value]) => {
        if (!['dontKnow', 'budget'].includes(key)) {
          formData.append(key, value);
        }
      });

      formData.append('services', services);
      formData.append('budget', budget);
      formData.append('task', task);

      if (file) {
        formData.append('file', file);
      }

      const send = await sendMail(formData);

      if (!send) {
        setIsSendError(true);
      } else {
        setIsSendSuccess(true);
        setFormField(initFormState);
        setWorkTypes(initWorkTypesState);
        setFile(null);

        if (fileRef) {
          fileRef.current.value = '';
        }
      }

      setIsSending(false);
    }
  };

  return (
    <div data-scroll-offset="20%" data-scroll="" data-scroll-class="fadeUp-active" className="contactsForm toFadeUp">

      <form className="fields" onSubmit={sendForm}>
        <h5>Бриф</h5>
        <div className="info">
          <p>Контактная информация</p>
          <AnimatedInput type="name" setField={changeField} field={form.name} label="Имя*" required />
          <AnimatedInput type="company" setField={changeField} field={form.company} label="Компания" />
          <AnimatedInput type="phone" setField={changeField} field={form.phone} label="Телефон" htmlType="tel" />
          <AnimatedInput type="email" setField={changeField} field={form.email} label="Email*" htmlType="email" required />
        </div>
        <div className="service">
          <p>Услуги</p>
          <div className="workTypes">
            <WorkTypeButton type="app" typeName={workTypesList.app} value={workTypes.app} changeWorkType={changeWorkType} />
            <WorkTypeButton type="site" typeName={workTypesList.site} value={workTypes.site} changeWorkType={changeWorkType} />
            <WorkTypeButton type="landing" typeName={workTypesList.landing} value={workTypes.landing} changeWorkType={changeWorkType} />
            <WorkTypeButton type="support" typeName={workTypesList.support} value={workTypes.support} changeWorkType={changeWorkType} />
            <WorkTypeButton type="development" typeName={workTypesList.development} value={workTypes.development} changeWorkType={changeWorkType} />
            <WorkTypeButton type="design" typeName={workTypesList.design} value={workTypes.design} changeWorkType={changeWorkType} />
            <WorkTypeButton type="additional" typeName={workTypesList.additional} value={workTypes.additional} changeWorkType={changeWorkType} />
            <WorkTypeButton type="idk" typeName={workTypesList.idk} value={workTypes.idk} changeWorkType={changeWorkType} />
          </div>
        </div>
        <div className="budget">
          <p>Бюджет</p>
          <AnimatedInput type="budget" setField={changeField} field={form.budget} label="Сколько денег вы выделили на свой проект?" />
          <div className="row">
            <div className="animatedCheckbox">
              <input checked={form.dontKnow} type="checkbox" onChange={() => changeField('dontKnow', !form.dontKnow)} />
              <img style={form.dontKnow ? { display: 'block' } : { display: 'none' }} alt="" src={checkedField} />
            </div>
            <p>Я не знаю</p>
          </div>

        </div>
        <div className="task">
          <p>Задание</p>
          <textarea placeholder="Расскажите о проекте подробнее" value={task} onChange={changeTaskHandler} />
        </div>
        <div className="file">
          <div>
            <label htmlFor="files" className="btn">Прикрепить файл</label>
            <input
              id="files"
              type="file"
              style={{ visibility: 'hidden', width: 0 }}
              ref={fileRef}
              onChange={setFileHandler}
            />
          </div>
          {file && (
            <button className="row fileRow" type="button" onClick={removeFileHandler}>
              <span className="filename">{file?.name}</span>
              <img src={cross} alt="" />
            </button>
          )}
        </div>

        <div className="send">
          {isSendError && <p className="contactsForm_error">Ошибка отправки заявки!</p>}
          {isSendSuccess && <p className="contactsForm_success">Заявка отправлена!</p>}
          <label>
            Нажимая кнопку “Отправить”,
            вы даёте согласие на обработку персональных данных
          </label>
          <div data-scroll-offset="-50%, 0" data-scroll-position="bottom" data-scroll="" data-scroll-speed="-2" data-scroll-direction="horizontal" className="absHolder">
            <button
              type="submit"
              data-strength-text="50"
              data-strength="100"
              className="animatedCircleButton magnetic"
              disabled={isSending}
            >
              <p className="btn-text">{isSending ? 'Отправка...' : 'Отправить'}</p>
              <div className="btn-fill buttonBack" />
            </button>
          </div>
        </div>
      </form>
      <div className="contacts">
        <h5>Контакты</h5>
        <label>Email</label>
        <a href="mailto:contact@polydev.io">contact@polydev.io</a>
        <label>Телефон</label>
        <a href="tel:+7 (995)-987-05-98">+7 (995)-987-05-98</a>
        <label>Мы в социальных сетях</label>
        <div className="row">
          <a href="https://dribbble.com/Polydev" className="animatedLink" target="_blank" rel="noreferrer">Dribbble</a>
          <a href="https://www.behance.net/polidev_io" className="animatedLink" target="_blank" rel="noreferrer">Behance</a>
        </div>
      </div>
    </div>
  );
}
