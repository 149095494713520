import React, { useEffect } from 'react';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ProjectBanner from './atoms/ProjectBanner/ProjectBanner';
import ProjectAbout from './atoms/ProjectAbout/ProjectAbout';
import MainScreens from './atoms/MainScreens/MainScreens';
import Footer from '../../shared_components/Footer/Footer';
import LetsWorkTogether from '../../shared_components/LetsWorkTogether/LetsWorkTogether';
import NextProject from './atoms/NextProject/NextProject';
import Header from '../../shared_components/Header/Header';
import './ProjectPage.css';

import bannerImgDelayX1600 from './img/delay/1600/bannerPageDelay1.jpg';
import projectImgDelayX1600 from './img/delay/1600/projectPageDelay1.jpg';
import delayScreenImg1X1600 from './img/delay/1600/screen1.jpg';
import delayScreenImg2X1600 from './img/delay/1600/screen2.jpg';
import delayScreenImg3X1600 from './img/delay/1600/screen3.jpg';
import delayScreenImg4X1600 from './img/delay/1600/screen4.jpg';
import delayBranding1X1600 from './img/delay/1600/screenBranding1.jpg';
import delayBranding2X1600 from './img/delay/1600/screenBranding2.jpg';
import delayBranding3X1600 from './img/delay/1600/screenBranding3.jpg';
import delayBranding4X1600 from './img/delay/1600/screenBranding4.jpg';
import bannerImgDelayX1000 from './img/delay/1000/bannerPageDelay1.jpg';
import projectImgDelayX1000 from './img/delay/1000/projectPageDelay1.jpg';
import delayScreenImg1X1000 from './img/delay/1000/screen1.jpg';
import delayScreenImg2X1000 from './img/delay/1000/screen2.jpg';
import delayScreenImg3X1000 from './img/delay/1000/screen3.jpg';
import delayScreenImg4X1000 from './img/delay/1000/screen4.jpg';
import delayBranding1X1000 from './img/delay/1000/screenBranding1.jpg';
import delayBranding2X1000 from './img/delay/1000/screenBranding2.jpg';
import delayBranding3X1000 from './img/delay/1000/screenBranding3.jpg';
import delayBranding4X1000 from './img/delay/1000/screenBranding4.jpg';
import bannerImgDelayX640 from './img/delay/640/bannerPageDelay1.jpg';
import projectImgDelayX640 from './img/delay/640/projectPageDelay1.jpg';
import delayScreenImg1X640 from './img/delay/640/screen1.jpg';
import delayScreenImg2X640 from './img/delay/640/screen2.jpg';
import delayScreenImg3X640 from './img/delay/640/screen3.jpg';
import delayScreenImg4X640 from './img/delay/640/screen4.jpg';
import delayBranding1X640 from './img/delay/640/screenBranding1.jpg';
import delayBranding2X640 from './img/delay/640/screenBranding2.jpg';
import delayBranding3X640 from './img/delay/640/screenBranding3.jpg';
import delayBranding4X640 from './img/delay/640/screenBranding4.jpg';
import bannerImgDelayX320 from './img/delay/320/bannerPageDelay1.jpg';
import projectImgDelayX320 from './img/delay/320/projectPageDelay1.jpg';
import delayScreenImg1X320 from './img/delay/320/screen1.jpg';
import delayScreenImg2X320 from './img/delay/320/screen2.jpg';
import delayScreenImg3X320 from './img/delay/320/screen3.jpg';
import delayScreenImg4X320 from './img/delay/320/screen4.jpg';
import delayBranding1X320 from './img/delay/320/screenBranding1.jpg';
import delayBranding2X320 from './img/delay/320/screenBranding2.jpg';
import delayBranding3X320 from './img/delay/320/screenBranding3.jpg';
import delayBranding4X320 from './img/delay/320/screenBranding4.jpg';

import bannerImgGremmX1600 from './img/gremm/1600/projectBannerImg.jpg';
import bannerImgGremmX1000 from './img/gremm/1000/projectBannerImg.jpg';
import bannerImgGremmX640 from './img/gremm/640/projectBannerImg.jpg';
import bannerImgGremmX320 from './img/gremm/320/projectBannerImg.jpg';

let bannerImgDelay;
let projectImgDelay;
let delayScreenImg1;
let delayScreenImg2;
let delayScreenImg3;
let delayScreenImg4;
let delayBranding1;
let delayBranding2;
let delayBranding3;
let delayBranding4;
let bannerImgGremm;

function updateImgs() {
  if (window.innerWidth >= 1200) {
    bannerImgDelay = bannerImgDelayX1600;
    projectImgDelay = projectImgDelayX1600;
    delayScreenImg1 = delayScreenImg1X1600;
    delayScreenImg2 = delayScreenImg2X1600;
    delayScreenImg3 = delayScreenImg3X1600;
    delayScreenImg4 = delayScreenImg4X1600;
    delayBranding1 = delayBranding1X1600;
    delayBranding2 = delayBranding2X1600;
    delayBranding3 = delayBranding3X1600;
    delayBranding4 = delayBranding4X1600;
    bannerImgGremm = bannerImgGremmX1600;
  } else if (window.innerWidth < 1200 && window.innerWidth >= 900) {
    bannerImgDelay = bannerImgDelayX1000;
    projectImgDelay = projectImgDelayX1000;
    delayScreenImg1 = delayScreenImg1X1000;
    delayScreenImg2 = delayScreenImg2X1000;
    delayScreenImg3 = delayScreenImg3X1000;
    delayScreenImg4 = delayScreenImg4X1000;
    delayBranding1 = delayBranding1X1000;
    delayBranding2 = delayBranding2X1000;
    delayBranding3 = delayBranding3X1000;
    delayBranding4 = delayBranding4X1000;
    bannerImgGremm = bannerImgGremmX1000;
  } else if (window.innerWidth < 900 && window.innerWidth >= 600) {
    bannerImgDelay = bannerImgDelayX640;
    projectImgDelay = projectImgDelayX640;
    delayScreenImg1 = delayScreenImg1X640;
    delayScreenImg2 = delayScreenImg2X640;
    delayScreenImg3 = delayScreenImg3X640;
    delayScreenImg4 = delayScreenImg4X640;
    delayBranding1 = delayBranding1X640;
    delayBranding2 = delayBranding2X640;
    delayBranding3 = delayBranding3X640;
    delayBranding4 = delayBranding4X640;
    bannerImgGremm = bannerImgGremmX640;
  } else if (window.innerWidth < 600) {
    bannerImgDelay = bannerImgDelayX320;
    projectImgDelay = projectImgDelayX320;
    delayScreenImg1 = delayScreenImg1X320;
    delayScreenImg2 = delayScreenImg2X320;
    delayScreenImg3 = delayScreenImg3X320;
    delayScreenImg4 = delayScreenImg4X320;
    delayBranding1 = delayBranding1X320;
    delayBranding2 = delayBranding2X320;
    delayBranding3 = delayBranding3X320;
    delayBranding4 = delayBranding4X320;
    bannerImgGremm = bannerImgGremmX320;
  }
}
updateImgs();

const initPageData = {
  banner: {
    name: 'Делай вещи',
    services: 'Разработка',
    image: bannerImgDelay,
    buttonText: 'Проект не был завершен',
  },
  about: {
    text: 'Делай Вещи — это компания по производству современной одежды на заказ.',
    tasks: ['Разработали приложение под iOS', 'Разработали админ-панель', 'Оказали поддержку'],
    year: '2021',
  },
  firstImage: projectImgDelay,
  mainScreens: {
    isScreens: true,
    label: 'Основные экраны',
    text: 'На главном экране располагаются акции и каталог товаров. Карточки товаров выполнены в виде поп-апов со всей необходимой информацией. Также в приложении есть 2 дополнительных раздела: личный кабинет и контакты.',
    images: [
      {
        img: delayScreenImg1,
        type: 'largeImg',
      }, {
        img: delayScreenImg2,
        type: 'mediumImg',
      }, {
        img: delayScreenImg3,
        type: 'mediumImg',
      }, {
        img: delayScreenImg4,
        type: 'longLowImg',
      }],
  },
  branding: {
    isScreens: false,
    title: 'Брэндинг',
    blocks: [
      {
        img: delayBranding1,
        type: 'longLowImg',
      }, {
        img: delayBranding2,
        type: 'mediumImg',
      }, {
        img: [delayBranding3, delayBranding4],
        type: 'leftTopRightBottomBigImgs',
      }],
  },
  nextProject: {
    name: 'Грэмм Групп ',
    image: bannerImgGremm,
    link: '/gremm',
  },
};

export default function DelayPage() {
  const [pageData, setPageData] = React.useState(initPageData);
  const [currentSize, setCurrentSize] = React.useState(0);
  const { scroll } = useLocomotiveScroll();
  const { width } = useWindowDimensions();

  function updateCaseObject() {
    const newPageData = { ...pageData };

    newPageData.banner.image = bannerImgGremm;
    newPageData.banner.image = bannerImgDelay;
    newPageData.firstImage = projectImgDelay;
    newPageData.mainScreens.images = [
      {
        img: delayScreenImg1,
        type: 'largeImg',
      }, {
        img: delayScreenImg2,
        type: 'mediumImg',
      }, {
        img: delayScreenImg3,
        type: 'mediumImg',
      }, {
        img: delayScreenImg4,
        type: 'longLowImg',
      }];
    newPageData.branding.blocks = [
      {
        img: delayBranding1,
        type: 'longLowImg',
      }, {
        img: delayBranding2,
        type: 'mediumImg',
      }, {
        img: [delayBranding3, delayBranding4],
        type: 'leftTopRightBottomBigImgs',
      }];
    newPageData.nextProject.image = bannerImgGremm;

    setPageData(newPageData);
  }

  useEffect(() => {
    if (width >= 1200) {
      if (currentSize !== 1440) {
        updateImgs();
        updateCaseObject();
        setCurrentSize(1440);
      }
    } else if (width < 1200 && width >= 900) {
      if (currentSize !== 1000) {
        updateImgs();
        updateCaseObject();
        setCurrentSize(1000);
      }
    } else if (width < 900 && width >= 600) {
      if (currentSize !== 640) {
        updateImgs();
        updateCaseObject();
        setCurrentSize(640);
      }
    } else if (width < 600) {
      if (currentSize !== 320) {
        updateImgs();
        updateCaseObject();
        setCurrentSize(320);
      }
    }
  }, [width]);

  useEffect(() => {
    if (scroll) {
      const header = document.getElementsByClassName('header')[0];
      let prevScrollpos = 0;
      scroll.on('scroll', (e) => {
        const currentScrollPos = e.scroll.y;
        if (currentScrollPos > 0) {
          header.classList.add('filled');
        }
        if (currentScrollPos - prevScrollpos > 10) {
          header.classList.add('hidden');
          prevScrollpos = currentScrollPos;
        }
        if (prevScrollpos - currentScrollPos > 10) {
          header.classList.remove('hidden');
          prevScrollpos = currentScrollPos;
        }
        if (currentScrollPos < 50) {
          header.classList.remove('filled');
        }
        if (currentScrollPos < 10) {
          header.classList.remove('hidden');
        }
      });
    }
  }, [scroll]);

  return (
    <div data-scroll-section="" className="projectPage">
      <Header color="black" />
      <ProjectBanner
        name={pageData.banner.name}
        services={pageData.banner.services}
        image={pageData.banner.image}
        buttonText={pageData.banner.buttonText}
        link={pageData.banner.buttonLink}
      />
      <ProjectAbout
        text={pageData.about.text}
        tasks={pageData.about.tasks}
        year={pageData.about.year}
      />

      <div className="parallaxBlock">
        <img data-scroll="" data-scroll-speed="-1.5" alt="" src={pageData.firstImage} />
      </div>

      <MainScreens
        isScreens={pageData.mainScreens.isScreens}
        label={pageData.mainScreens.label}
        text={pageData.mainScreens.text}
        blocks={pageData.mainScreens.images}
      />
      <MainScreens
        isScreens={pageData.branding.isScreens}
        title={pageData.branding.title}
        blocks={pageData.branding.blocks}
      />

      <NextProject
        link={pageData.nextProject.link}
        image={pageData.nextProject.image}
        name={pageData.nextProject.name}
      />

      <LetsWorkTogether />
      <Footer />
    </div>
  );
}
