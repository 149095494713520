import React, { useEffect } from 'react';
// eslint-disable-next-line import/extensions
import anime from 'animejs/lib/anime.es.js';
import { Cursor } from 'react-creative-cursor';
import { Link } from 'react-router-dom';
import Marquee from 'react-fast-marquee';
import topImg1600 from './img/1600/topImg.jpg';
import leftImg1600 from './img/1600/leftImg.jpg';
import rightImg1600 from './img/1600/rightImg.jpg';
import topImg1000 from './img/1000/topImg.jpg';
import leftImg1000 from './img/1000/leftImg.jpg';
import rightImg1000 from './img/1000/rightImg.jpg';
import topImg640 from './img/640/topImg.jpg';
import leftImg640 from './img/640/leftImg.jpg';
import rightImg640 from './img/640/rightImg.jpg';
import './Banner.css';
import 'react-creative-cursor/dist/styles.css';
import FilledFromLeft from '../../../../shared_components/FilledFromLeft/FilledFromLeft';

export default function Banner() {
  const [topImg, setTopImg] = React.useState(topImg1600);
  const [rightImg, setRightImg] = React.useState(rightImg1600);
  const [leftImg, setLeftImg] = React.useState(leftImg1600);
  const [marqueeSpeed, setMarqueeSpeed] = React.useState(75);
  useEffect(() => {
    if (window.innerWidth > 1199) {
      setTopImg(topImg1600);
      setLeftImg(leftImg1600);
      setRightImg(rightImg1600);
      setMarqueeSpeed(75);
    } else if (window.innerWidth <= 1199 && window.innerWidth > 899) {
      setTopImg(topImg1000);
      setLeftImg(leftImg1000);
      setRightImg(rightImg1000);
      setMarqueeSpeed(75);
    } else if (window.innerWidth <= 899) {
      setTopImg(topImg640);
      setLeftImg(leftImg640);
      setRightImg(rightImg640);
      setMarqueeSpeed(35);
    }
  }, [window.innerWidth]);
  function showBanner() {
    const animation = anime.timeline({
      easing: 'linear',
      duration: 500,
    });
    animation
      .add({
        targets: ['.runningLine', '.textBlock'],
        delay: 0,
        duration: 200,
        opacity: 1,
      })
      .add({
        targets: '.bannerLeftImg',
        delay: 0,
        opacity: 1,
      })
      .add({
        targets: '.bannerTopImg',
        opacity: 1,
      })
      .add({
        targets: '.bannerRightImg',
        opacity: 1,
      });
  }
  useEffect(() => {
    showBanner();
  }, []);
  return (
    <div className="banner">
      <div className="container">
        <Cursor cursorSize={0} isGelly={false} />
        <img alt="" data-strength-text="50" data-strength="100" className="bannerTopImg magnetic" src={topImg} />
        <img alt="" data-strength-text="50" data-strength="100" className="bannerLeftImg magnetic" src={leftImg} />
        <img alt="" data-strength-text="50" data-strength="100" className="bannerRightImg magnetic" src={rightImg} />
        <Link to="/contacts">
          <div className="textBlock">
            <p>
              Мы делаем современные
              <br />
              digital-продукты для бизнеса
            </p>
            <FilledFromLeft name="Оставить заявку" />
          </div>
        </Link>
      </div>
      <div className="runningLine">
        <Marquee className="runningLine__block" speed={marqueeSpeed} gradient={false}>
          &nbsp;— Сайты — Приложения — Поддержка&nbsp;
        </Marquee>
      </div>
    </div>
  );
}
