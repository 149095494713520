import React from 'react';

export default function WorkTypeButton({
  type, typeName, value, changeWorkType,
}) {
  const classes = value ? 'worksType active' : 'worksType';

  const onClickHandler = () => {
    changeWorkType(type, !value);
  };

  return (
    <div
      role="button"
      tabIndex="0"
      onClick={onClickHandler}
      className={classes}
    >
      {typeName}
    </div>
  );
}
