import React, { useEffect } from 'react';
import './AboutUsPage.css';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import aboutUsImg1 from './img/aboutUsImg1.jpg';
import aboutUsImg2 from './img/aboutUsImg2.jpg';
import aboutUsImg3 from './img/aboutUsImg3.jpg';
import SmallDropdownList from './atoms/SmallDropdownList/SmallDropdownList';
import LetsWorkTogether from '../../shared_components/LetsWorkTogether/LetsWorkTogether';
import Footer from '../../shared_components/Footer/Footer';
import RunningPageIcons from '../../shared_components/RunningPageIcons/RunningPageIcons';
import ServicesList from '../MainPage/atoms/ServicesList/ServicesList';
import Header from '../../shared_components/Header/Header';
import { caseImgs, smallDropdownList } from './aboutUsLists';

export default function AboutUsPage() {
  const { scroll } = useLocomotiveScroll();
  const [currentWidth, setCurrentWidth] = React.useState(1200);
  useEffect(() => {
    if (scroll) {
      const header = document.getElementsByClassName('header')[0];
      let prevScrollpos = 0;
      scroll.on('scroll', (e) => {
        const currentScrollPos = e.scroll.y;
        if (currentScrollPos > 0) {
          header.classList.add('filled');
        }
        if (currentScrollPos - prevScrollpos > 10) {
          header.classList.add('hidden');
          prevScrollpos = currentScrollPos;
        }
        if (prevScrollpos - currentScrollPos > 10) {
          header.classList.remove('hidden');
          prevScrollpos = currentScrollPos;
        }
        if (currentScrollPos < 50) {
          header.classList.remove('filled');
        }
        if (currentScrollPos < 10) {
          header.classList.remove('hidden');
        }
      });
    }
  }, [scroll]);
  window.onresize = () => {
    if (scroll) {
      if (window.innerWidth >= 1200 && currentWidth !== 1200) {
        setCurrentWidth(1200);
      } else if (window.innerWidth < 1200 && window.innerWidth >= 900 && currentWidth !== 900) {
        setCurrentWidth(900);
      } else if (window.innerWidth < 900 && window.innerWidth >= 600 && currentWidth !== 600) {
        setCurrentWidth(600);
      }
    }
  };
  return (
    <div data-scroll-section="" className="aboutUsPage">
      <Header color="black" />
      <div className="row container heading">
        <h2 data-scroll-offset="20%" data-scroll="" data-scroll-class="fadeUp-active" className="toFadeUp">О нас</h2>
        <p data-scroll-offset="20%" data-scroll="" data-scroll-class="fadeUp-active" className="toFadeUp">
          Создаём современные
          <br />
          Digital-продукты на пользу бизнесу
        </p>
      </div>
      <div>
        <div data-scroll-offset="20%" data-scroll-class="fadeUp-active" data-scroll="" className="parallaxBlock toFadeUp">
          <img data-scroll="" data-scroll-speed="-1.5" alt="" src={aboutUsImg1} />
        </div>
      </div>
      <div className="container firstBlock">
        <p data-scroll-offset="20%" data-scroll="" data-scroll-class="fadeUp-active" className="toFadeUp">
          В каждом проекте команда Polydev ориентируется
          <span> </span>
          <br className="br-900" />
          на задачи бизнеса.
          Мы анализируем целевую аудиторию, учитываем требования рынка, создаём дизайн
          на основе трендов и поведения пользователей,
          поддерживаем продукты после релиза.
        </p>
        <div data-scroll-offset="20%" data-scroll="" data-scroll-class="fadeUp-active" className="row toFadeUp">
          <div className="text">
            <label>Цифры и факты</label>
            <p>
              Наши решения основаны на UX-аналитике:
              изучении отрасли и конкурентов.
              Мы создаём эстетичный визуал продукта без лишнего функционала,
              выводящий бизнес клиента на новый уровень и
              решающий задачи пользователя.
            </p>
            <div className="row">
              <div className="number">
                <h3>
                  30
                  <span>+</span>
                </h3>
                <p>Довольных клиентов</p>
              </div>
              <div className="number">
                <h3>
                  50
                  <span>+</span>
                </h3>
                <p>Реализованных проектов</p>
              </div>
            </div>
          </div>
          <div className="smallDropdownBlock">
            <label>Принципы работы</label>
            <SmallDropdownList list={smallDropdownList} />
          </div>
        </div>
      </div>
      <div className="parallaxBlock">
        <img data-scroll="" data-scroll-speed="-1.5" alt="" src={aboutUsImg2} />
      </div>
      <div className="container secondBlock">
        <label>Услуги</label>
        <ServicesList type="static" />
      </div>
      <div className="container thirdBlock">
        <label data-scroll-offset="20%" data-scroll="" data-scroll-class="fadeUp-active" className="toFadeUp">Клиенты</label>
        <p data-scroll-offset="20%" data-scroll="" data-scroll-class="fadeUp-active" className="toFadeUp">
          Среди наших клиентов есть разные компании:
          от стартапов до лидеров рынка.
          Их объединяет желание найти
          оптимальные решения для своего бизнеса, в чём мы
          <br />
          с удовольствием помогаем.
        </p>
        <div data-scroll-offset="20%" data-scroll="" data-scroll-class="fadeUp-active" className="row toFadeUp">
          {caseImgs.map((item, itemOrderId) => (
            <div key={itemOrderId} className="imgCard">
              <img alt="" src={item} />
            </div>
          ))}
        </div>
      </div>
      <div className="fifthBlock">
        <div data-scroll-offset="20%" data-scroll="" data-scroll-class="fadeUp-active" className="container toFadeUp">
          <div className="row">
            <img src={aboutUsImg3} alt="" />
            <div className="text">
              <h3>
                Работа
                <span> </span>
                <br className="br-1024" />
                у нас
              </h3>
              <p>
                Если вы хотите работать у нас – напишите в телеграм нашему HR.
                В сообщении укажите свои навыки, знание софта, прикрепите реальные
                или учебные работы, либо ссылки на портфолио.
              </p>
              <a className="animatedLink" href="https://t.me/polydev_s">
                <span>https://t.me/polydev_s</span>
                <div className="bottomLine green" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <RunningPageIcons />
      <LetsWorkTogether />
      <Footer />
    </div>
  );
}
