import React from 'react';
import './ReviewsSlider.css';
import arrowLeft from './img/arrowLeft.svg';
import arrowRight from './img/arrowRight.svg';

const reviews = [{
  review: '«Polydev отличается гибкостью и высокой скоростью разработки. В нашем непростом кейсе получилось добиться нужного результата за счёт плотного контакта и глубогого погружения студии в нашу специфику. Студией были найдены и успешно протестированы лучшие решения, которые впоследствии привели нас к первым клиентам»',
  author: 'Иван',
  position: 'Руководитель проекта SwiftGlobal',
}, {
  review: '«Мы сотрудничаем с Polydev более двух лет. За это время не один раз убедились в их компетенциях. Нам нравится, что Polydev ориентируется на результаты, а не на работу ”для галочки”. В каждом совместном проекте мы видели от ребят максимальный отклик и погружение, что позволяло создавать действительно крутые и эффективные продукты»',
  author: 'Ильнар',
  position: 'Генеральный директор',
},
];

export default function ReviewsSlider() {
  const [reviewId, setReviewId] = React.useState(0);

  function nextReview() {
    if (reviewId < reviews.length - 1) {
      setReviewId(reviewId + 1);
    }
  }
  function prevReview() {
    if (reviewId > 0) {
      setReviewId(reviewId - 1);
    }
  }
  return (
    <div className="reviewsSlider">
      <div data-scroll-offset="20%" data-scroll="" data-scroll-class="fadeUp-active" className="container toFadeUp">
        <h4>{reviews[reviewId].review}</h4>
        <p>{reviews[reviewId].author}</p>
        <label>{reviews[reviewId].position}</label>
        <div className="row">
          <img alt="" onClick={() => { prevReview(); }} src={arrowLeft} />
          <span>
            {reviewId + 1}
            {' '}
            /
            {' '}
            {reviews.length}
          </span>
          <img alt="" onClick={() => { nextReview(); }} src={arrowRight} />
        </div>
      </div>
    </div>
  );
}
