import React from 'react';
import DropdownList from '../DropdownList/DropdownList';
import './ServicesList.css';

const list = [{
  name: 'Приложения',
  description: 'Мобильные приложения под платформы iOS и Android на нативных языках',
  stages: [{
    name: 'Брифинг',
    time: '2 дня',
  }, {
    name: 'UX/Аналитика',
    time: 'от 5 дней',
  }, {
    name: 'Дизайн-концепция',
    time: 'от 5 дней',
  }, {
    name: 'Дизайн всех экранов',
    time: 'от 10 дней',
  }, {
    name: 'Разработка',
    time: 'от 15 дней',
  }],
}, {
  name: 'Сайты',
  description: 'Корпоративные сайты, интернет-магазины, СМИ, портфолио',
  stages: [{
    name: 'Брифинг',
    time: '2 дня',
  }, {
    name: 'UX/Аналитика',
    time: 'от 5 дней',
  }, {
    name: 'Дизайн-концепция',
    time: 'от 5 дней',
  }, {
    name: 'Внутренние страницы',
    time: 'от 10 дней',
  }, {
    name: 'Адаптивы',
    time: 'от 10 дней',
  }, {
    name: 'Разработка',
    time: 'от 15 дней',
  }],
}, {
  name: 'Поддержка',
  description: 'Сделаем новые версии, будем постепенно улучшать продукт, изменять его по запросу',
  stages: [{
    name: 'Обновление контента',
    time: '',
  }, {
    name: 'Оперативное исправление проблем',
    time: '',
  }, {
    name: 'Пользовательские интервью',
    time: '',
  }, {
    name: 'Консультации',
    time: '',
  }],
}, {
  name: 'Дополнительно',
  description: 'Можем сделать логотип, брендбук, оформим соц.сети и презентации',
  stages: [{
    name: 'Брендинг',
    time: 'от 5 дней',
  }, {
    name: 'Внутренние страницы',
    time: 'от 3 дней',
  }, {
    name: 'Адаптивы',
    time: 'от 5 дней',
  }, {
    name: 'Разработка',
    time: 'от 3 дней',
  }],
}];

export default function ServicesList(props) {
  let isStatic = false;
  if (props.type === 'static') {
    isStatic = true;
  }
  return (
    <div className={isStatic ? 'servicesList static' : 'servicesList'}>
      {list.map((item, id) => (
        <DropdownList
          isStatic={isStatic}
          id={id}
          stages={item.stages}
          name={item.name}
          description={item.description}
          key={item.name}
        />
      ))}
    </div>
  );
}
