import React from 'react';
import './ClientCard.css';

export default function ClientCard(props) {
  return (
    <div data-scroll-offset="20%" data-scroll="" data-scroll-class="fadeUp-active" className="clientCard toFadeUp">
      <div className="text">
        <h3>{props.title}</h3>
        <p>{props.description}</p>
      </div>
      <img alt="" src={props.img} />
    </div>
  );
}
