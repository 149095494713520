import React from 'react';
import './AnimatedLink.css';
import { Link } from 'react-router-dom';

export default function AnimatedLink(props) {
  return (
    <Link className="animatedLink" to={props.link}>
      <span>{props.text}</span>
      <div className="bottomLine" />
    </Link>
  );
}
