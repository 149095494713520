import React from 'react';
import './DropdownList.css';

export default function DropdownList(props) {
  const [isShowed, setIsShowed] = React.useState(false);
  function clickHandler() {
    setIsShowed(!isShowed);
  }

  return (
    <div data-scroll-offset="20%" data-scroll="" data-scroll-class="fadeUp-active" className="toFadeUp">
      <div className={isShowed ? props.isStatic ? 'dropdownList static opened' : 'dropdownList opened' : props.isStatic ? 'dropdownList static opened' : 'dropdownList'}>
        <div tabIndex={0} role="button" onClick={() => clickHandler()} className={props.isStatic ? 'row notPointed' : 'row'}>
          <label>{`0${props.id + 1}`}</label>
          <div className="row forTablet">
            <h2>{props.name}</h2>
            <p>{props.description}</p>
          </div>
          <div className="cross">
            <div className="vertPart" />
            <div className="horizPart" />
          </div>
        </div>
        <div className="list">
          {props.stages.map((stage, stageOrderId) => (
            <div key={stageOrderId} className="stage row">
              <p>{stage.name}</p>
              <label>{stage.time}</label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
